export function Cytobands() {
  return {
    "chr1": [
      {"referenceName": "chr1", "start": 0, "end": 2300000, "cytoband": "p36.33", "stain": "gneg"},
      {"referenceName": "chr1", "start": 2300000, "end": 5300000, "cytoband": "p36.32", "stain": "gpos25"},
      {"referenceName": "chr1", "start": 5300000, "end": 7100000, "cytoband": "p36.31", "stain": "gneg"},
      {"referenceName": "chr1", "start": 7100000, "end": 9100000, "cytoband": "p36.23", "stain": "gpos25"},
      {"referenceName": "chr1", "start": 9100000, "end": 12500000, "cytoband": "p36.22", "stain": "gneg"},
      {"referenceName": "chr1", "start": 12500000, "end": 15900000, "cytoband": "p36.21", "stain": "gpos50"},
      {"referenceName": "chr1", "start": 15900000, "end": 20100000, "cytoband": "p36.13", "stain": "gneg"},
      {"referenceName": "chr1", "start": 20100000, "end": 23600000, "cytoband": "p36.12", "stain": "gpos25"},
      {"referenceName": "chr1", "start": 23600000, "end": 27600000, "cytoband": "p36.11", "stain": "gneg"},
      {"referenceName": "chr1", "start": 27600000, "end": 29900000, "cytoband": "p35.3", "stain": "gpos25"},
      {"referenceName": "chr1", "start": 29900000, "end": 32300000, "cytoband": "p35.2", "stain": "gneg"},
      {"referenceName": "chr1", "start": 32300000, "end": 34300000, "cytoband": "p35.1", "stain": "gpos25"},
      {"referenceName": "chr1", "start": 34300000, "end": 39600000, "cytoband": "p34.3", "stain": "gneg"},
      {"referenceName": "chr1", "start": 39600000, "end": 43700000, "cytoband": "p34.2", "stain": "gpos2512"},
      {"referenceName": "chr1", "start": 43700000, "end": 46300000, "cytoband": "p34.1", "stain": "gneg"},
      {"referenceName": "chr1", "start": 46300000, "end": 50200000, "cytoband": "p33", "stain": "gpos75"},
      {"referenceName": "chr1", "start": 50200000, "end": 55600000, "cytoband": "p32.3", "stain": "gneg"},
      {"referenceName": "chr1", "start": 55600000, "end": 58500000, "cytoband": "p32.2", "stain": "gpos50"},
      {"referenceName": "chr1", "start": 58500000, "end": 60800000, "cytoband": "p32.1", "stain": "gneg"},
      {"referenceName": "chr1", "start": 60800000, "end": 68500000, "cytoband": "p31.3", "stain": "gpos50"},
      {"referenceName": "chr1", "start": 68500000, "end": 69300000, "cytoband": "p31.2", "stain": "gneg"},
      {"referenceName": "chr1", "start": 69300000, "end": 84400000, "cytoband": "p31.1", "stain": "gpos100"},
      {"referenceName": "chr1", "start": 84400000, "end": 87900000, "cytoband": "p22.3", "stain": "gneg"},
      {"referenceName": "chr1", "start": 87900000, "end": 91500000, "cytoband": "p22.2", "stain": "gpos75"},
      {"referenceName": "chr1", "start": 91500000, "end": 94300000, "cytoband": "p22.1", "stain": "gneg"},
      {"referenceName": "chr1", "start": 94300000, "end": 99300000, "cytoband": "p21.3", "stain": "gpos75"},
      {"referenceName": "chr1", "start": 99300000, "end": 101800000, "cytoband": "p21.2", "stain": "gneg"},
      {"referenceName": "chr1", "start": 101800000, "end": 106700000, "cytoband": "p21.1", "stain": "gpos100"},
      {"referenceName": "chr1", "start": 106700000, "end": 111200000, "cytoband": "p13.3", "stain": "gneg"},
      {"referenceName": "chr1", "start": 111200000, "end": 115500000, "cytoband": "p13.2", "stain": "gpos50"},
      {"referenceName": "chr1", "start": 115500000, "end": 117200000, "cytoband": "p13.1", "stain": "gneg"},
      {"referenceName": "chr1", "start": 117200000, "end": 120400000, "cytoband": "p12", "stain": "gpos50"},
      {"referenceName": "chr1", "start": 120400000, "end": 121700000, "cytoband": "p11.2", "stain": "gneg"},
      {"referenceName": "chr1", "start": 121700000, "end": 123400000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr1", "start": 123400000, "end": 125100000, "cytoband": "q11", "stain": "acen"},
      {"referenceName": "chr1", "start": 125100000, "end": 143200000, "cytoband": "q12", "stain": "gvar"},
      {"referenceName": "chr1", "start": 143200000, "end": 147500000, "cytoband": "q21.1", "stain": "gneg"},
      {"referenceName": "chr1", "start": 147500000, "end": 150600000, "cytoband": "q21.2", "stain": "gpos50"},
      {"referenceName": "chr1", "start": 150600000, "end": 155100000, "cytoband": "q21.3", "stain": "gneg"},
      {"referenceName": "chr1", "start": 155100000, "end": 156600000, "cytoband": "q22", "stain": "gpos50"},
      {"referenceName": "chr1", "start": 156600000, "end": 159100000, "cytoband": "q23.1", "stain": "gneg"},
      {"referenceName": "chr1", "start": 159100000, "end": 160500000, "cytoband": "q23.2", "stain": "gpos50"},
      {"referenceName": "chr1", "start": 160500000, "end": 165500000, "cytoband": "q23.3", "stain": "gneg"},
      {"referenceName": "chr1", "start": 165500000, "end": 167200000, "cytoband": "q24.1", "stain": "gpos50"},
      {"referenceName": "chr1", "start": 167200000, "end": 170900000, "cytoband": "q24.2", "stain": "gneg"},
      {"referenceName": "chr1", "start": 170900000, "end": 173000000, "cytoband": "q24.3", "stain": "gpos75"},
      {"referenceName": "chr1", "start": 173000000, "end": 176100000, "cytoband": "q25.1", "stain": "gneg"},
      {"referenceName": "chr1", "start": 176100000, "end": 180300000, "cytoband": "q25.2", "stain": "gpos50"},
      {"referenceName": "chr1", "start": 180300000, "end": 185800000, "cytoband": "q25.3", "stain": "gneg"},
      {"referenceName": "chr1", "start": 185800000, "end": 190800000, "cytoband": "q31.1", "stain": "gpos100"},
      {"referenceName": "chr1", "start": 190800000, "end": 193800000, "cytoband": "q31.2", "stain": "gneg"},
      {"referenceName": "chr1", "start": 193800000, "end": 198700000, "cytoband": "q31.3", "stain": "gpos100"},
      {"referenceName": "chr1", "start": 198700000, "end": 207100000, "cytoband": "q32.1", "stain": "gneg"},
      {"referenceName": "chr1", "start": 207100000, "end": 211300000, "cytoband": "q32.2", "stain": "gpos25"},
      {"referenceName": "chr1", "start": 211300000, "end": 214400000, "cytoband": "q32.3", "stain": "gneg"},
      {"referenceName": "chr1", "start": 214400000, "end": 223900000, "cytoband": "q41", "stain": "gpos100"},
      {"referenceName": "chr1", "start": 223900000, "end": 224400000, "cytoband": "q42.11", "stain": "gneg"},
      {"referenceName": "chr1", "start": 224400000, "end": 226800000, "cytoband": "q42.12", "stain": "gpos25"},
      {"referenceName": "chr1", "start": 226800000, "end": 230500000, "cytoband": "q42.13", "stain": "gneg"},
      {"referenceName": "chr1", "start": 230500000, "end": 234600000, "cytoband": "q42.2", "stain": "gpos50"},
      {"referenceName": "chr1", "start": 234600000, "end": 236400000, "cytoband": "q42.3", "stain": "gneg"},
      {"referenceName": "chr1", "start": 236400000, "end": 243500000, "cytoband": "q43", "stain": "gpos75"},
      {"referenceName": "chr1", "start": 243500000, "end": 248956422, "cytoband": "q44", "stain": "gneg"}
    ],
    "chr10": [
      {"referenceName": "chr10", "start": 0, "end": 3000000, "cytoband": "p15.3", "stain": "gneg"},
      {"referenceName": "chr10", "start": 3000000, "end": 3800000, "cytoband": "p15.2", "stain": "gpos25"},
      {"referenceName": "chr10", "start": 3800000, "end": 6600000, "cytoband": "p15.1", "stain": "gneg"},
      {"referenceName": "chr10", "start": 6600000, "end": 12200000, "cytoband": "p14", "stain": "gpos75"},
      {"referenceName": "chr10", "start": 12200000, "end": 17300000, "cytoband": "p13", "stain": "gneg"},
      {"referenceName": "chr10", "start": 17300000, "end": 18300000, "cytoband": "p12.33", "stain": "gpos75"},
      {"referenceName": "chr10", "start": 18300000, "end": 18400000, "cytoband": "p12.32", "stain": "gneg"},
      {"referenceName": "chr10", "start": 18400000, "end": 22300000, "cytoband": "p12.31", "stain": "gpos75"},
      {"referenceName": "chr10", "start": 22300000, "end": 24300000, "cytoband": "p12.2", "stain": "gneg"},
      {"referenceName": "chr10", "start": 24300000, "end": 29300000, "cytoband": "p12.1", "stain": "gpos50"},
      {"referenceName": "chr10", "start": 29300000, "end": 31100000, "cytoband": "p11.23", "stain": "gneg"},
      {"referenceName": "chr10", "start": 31100000, "end": 34200000, "cytoband": "p11.22", "stain": "gpos25"},
      {"referenceName": "chr10", "start": 34200000, "end": 38000000, "cytoband": "p11.21", "stain": "gneg"},
      {"referenceName": "chr10", "start": 38000000, "end": 39800000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr10", "start": 39800000, "end": 41600000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr10", "start": 41600000, "end": 45500000, "cytoband": "q11.21", "stain": "gneg"},
      {"referenceName": "chr10", "start": 45500000, "end": 48600000, "cytoband": "q11.22", "stain": "gpos25"},
      {"referenceName": "chr10", "start": 48600000, "end": 51100000, "cytoband": "q11.23", "stain": "gneg"},
      {"referenceName": "chr10", "start": 51100000, "end": 59400000, "cytoband": "q21.1", "stain": "gpos100"},
      {"referenceName": "chr10", "start": 59400000, "end": 62800000, "cytoband": "q21.2", "stain": "gneg"},
      {"referenceName": "chr10", "start": 62800000, "end": 68800000, "cytoband": "q21.3", "stain": "gpos100"},
      {"referenceName": "chr10", "start": 68800000, "end": 73100000, "cytoband": "q22.1", "stain": "gneg"},
      {"referenceName": "chr10", "start": 73100000, "end": 75900000, "cytoband": "q22.2", "stain": "gpos50"},
      {"referenceName": "chr10", "start": 75900000, "end": 80300000, "cytoband": "q22.3", "stain": "gneg"},
      {"referenceName": "chr10", "start": 80300000, "end": 86100000, "cytoband": "q23.1", "stain": "gpos100"},
      {"referenceName": "chr10", "start": 86100000, "end": 87700000, "cytoband": "q23.2", "stain": "gneg"},
      {"referenceName": "chr10", "start": 87700000, "end": 91100000, "cytoband": "q23.31", "stain": "gpos75"},
      {"referenceName": "chr10", "start": 91100000, "end": 92300000, "cytoband": "q23.32", "stain": "gneg"},
      {"referenceName": "chr10", "start": 92300000, "end": 95300000, "cytoband": "q23.33", "stain": "gpos50"},
      {"referenceName": "chr10", "start": 95300000, "end": 97500000, "cytoband": "q24.1", "stain": "gneg"},
      {"referenceName": "chr10", "start": 97500000, "end": 100100000, "cytoband": "q24.2", "stain": "gpos50"},
      {"referenceName": "chr10", "start": 100100000, "end": 101200000, "cytoband": "q24.31", "stain": "gneg"},
      {"referenceName": "chr10", "start": 101200000, "end": 103100000, "cytoband": "q24.32", "stain": "gpos25"},
      {"referenceName": "chr10", "start": 103100000, "end": 104000000, "cytoband": "q24.33", "stain": "gneg"},
      {"referenceName": "chr10", "start": 104000000, "end": 110100000, "cytoband": "q25.1", "stain": "gpos100"},
      {"referenceName": "chr10", "start": 110100000, "end": 113100000, "cytoband": "q25.2", "stain": "gneg"},
      {"referenceName": "chr10", "start": 113100000, "end": 117300000, "cytoband": "q25.3", "stain": "gpos75"},
      {"referenceName": "chr10", "start": 117300000, "end": 119900000, "cytoband": "q26.11", "stain": "gneg"},
      {"referenceName": "chr10", "start": 119900000, "end": 121400000, "cytoband": "q26.12", "stain": "gpos50"},
      {"referenceName": "chr10", "start": 121400000, "end": 125700000, "cytoband": "q26.13", "stain": "gneg"},
      {"referenceName": "chr10", "start": 125700000, "end": 128800000, "cytoband": "q26.2", "stain": "gpos50"},
      {"referenceName": "chr10", "start": 128800000, "end": 133797422, "cytoband": "q26.3", "stain": "gneg"}
    ],
    "chr11": [
      {"referenceName": "chr11", "start": 0, "end": 2800000, "cytoband": "p15.5", "stain": "gneg"},
      {"referenceName": "chr11", "start": 2800000, "end": 11700000, "cytoband": "p15.4", "stain": "gpos50"},
      {"referenceName": "chr11", "start": 11700000, "end": 13800000, "cytoband": "p15.3", "stain": "gneg"},
      {"referenceName": "chr11", "start": 13800000, "end": 16900000, "cytoband": "p15.2", "stain": "gpos50"},
      {"referenceName": "chr11", "start": 16900000, "end": 22000000, "cytoband": "p15.1", "stain": "gneg"},
      {"referenceName": "chr11", "start": 22000000, "end": 26200000, "cytoband": "p14.3", "stain": "gpos100"},
      {"referenceName": "chr11", "start": 26200000, "end": 27200000, "cytoband": "p14.2", "stain": "gneg"},
      {"referenceName": "chr11", "start": 27200000, "end": 31000000, "cytoband": "p14.1", "stain": "gpos75"},
      {"referenceName": "chr11", "start": 31000000, "end": 36400000, "cytoband": "p13", "stain": "gneg"},
      {"referenceName": "chr11", "start": 36400000, "end": 43400000, "cytoband": "p12", "stain": "gpos100"},
      {"referenceName": "chr11", "start": 43400000, "end": 48800000, "cytoband": "p11.2", "stain": "gneg"},
      {"referenceName": "chr11", "start": 48800000, "end": 51000000, "cytoband": "p11.12", "stain": "gpos75"},
      {"referenceName": "chr11", "start": 51000000, "end": 53400000, "cytoband": "p11.11", "stain": "acen"},
      {"referenceName": "chr11", "start": 53400000, "end": 55800000, "cytoband": "q11", "stain": "acen"},
      {"referenceName": "chr11", "start": 55800000, "end": 60100000, "cytoband": "q12.1", "stain": "gpos75"},
      {"referenceName": "chr11", "start": 60100000, "end": 61900000, "cytoband": "q12.2", "stain": "gneg"},
      {"referenceName": "chr11", "start": 61900000, "end": 63600000, "cytoband": "q12.3", "stain": "gpos25"},
      {"referenceName": "chr11", "start": 63600000, "end": 66100000, "cytoband": "q13.1", "stain": "gneg"},
      {"referenceName": "chr11", "start": 66100000, "end": 68700000, "cytoband": "q13.2", "stain": "gpos25"},
      {"referenceName": "chr11", "start": 68700000, "end": 70500000, "cytoband": "q13.3", "stain": "gneg"},
      {"referenceName": "chr11", "start": 70500000, "end": 75500000, "cytoband": "q13.4", "stain": "gpos50"},
      {"referenceName": "chr11", "start": 75500000, "end": 77400000, "cytoband": "q13.5", "stain": "gneg"},
      {"referenceName": "chr11", "start": 77400000, "end": 85900000, "cytoband": "q14.1", "stain": "gpos100"},
      {"referenceName": "chr11", "start": 85900000, "end": 88600000, "cytoband": "q14.2", "stain": "gneg"},
      {"referenceName": "chr11", "start": 88600000, "end": 93000000, "cytoband": "q14.3", "stain": "gpos100"},
      {"referenceName": "chr11", "start": 93000000, "end": 97400000, "cytoband": "q21", "stain": "gneg"},
      {"referenceName": "chr11", "start": 97400000, "end": 102300000, "cytoband": "q22.1", "stain": "gpos100"},
      {"referenceName": "chr11", "start": 102300000, "end": 103000000, "cytoband": "q22.2", "stain": "gneg"},
      {"referenceName": "chr11", "start": 103000000, "end": 110600000, "cytoband": "q22.3", "stain": "gpos100"},
      {"referenceName": "chr11", "start": 110600000, "end": 112700000, "cytoband": "q23.1", "stain": "gneg"},
      {"referenceName": "chr11", "start": 112700000, "end": 114600000, "cytoband": "q23.2", "stain": "gpos50"},
      {"referenceName": "chr11", "start": 114600000, "end": 121300000, "cytoband": "q23.3", "stain": "gneg"},
      {"referenceName": "chr11", "start": 121300000, "end": 124000000, "cytoband": "q24.1", "stain": "gpos50"},
      {"referenceName": "chr11", "start": 124000000, "end": 127900000, "cytoband": "q24.2", "stain": "gneg"},
      {"referenceName": "chr11", "start": 127900000, "end": 130900000, "cytoband": "q24.3", "stain": "gpos50"},
      {"referenceName": "chr11", "start": 130900000, "end": 135086622, "cytoband": "q25", "stain": "gneg"}
    ],
    "chr12": [
      {"referenceName": "chr12", "start": 0, "end": 3200000, "cytoband": "p13.33", "stain": "gneg"},
      {"referenceName": "chr12", "start": 3200000, "end": 5300000, "cytoband": "p13.32", "stain": "gpos25"},
      {"referenceName": "chr12", "start": 5300000, "end": 10000000, "cytoband": "p13.31", "stain": "gneg"},
      {"referenceName": "chr12", "start": 10000000, "end": 12600000, "cytoband": "p13.2", "stain": "gpos75"},
      {"referenceName": "chr12", "start": 12600000, "end": 14600000, "cytoband": "p13.1", "stain": "gneg"},
      {"referenceName": "chr12", "start": 14600000, "end": 19800000, "cytoband": "p12.3", "stain": "gpos100"},
      {"referenceName": "chr12", "start": 19800000, "end": 21100000, "cytoband": "p12.2", "stain": "gneg"},
      {"referenceName": "chr12", "start": 21100000, "end": 26300000, "cytoband": "p12.1", "stain": "gpos100"},
      {"referenceName": "chr12", "start": 26300000, "end": 27600000, "cytoband": "p11.23", "stain": "gneg"},
      {"referenceName": "chr12", "start": 27600000, "end": 30500000, "cytoband": "p11.22", "stain": "gpos50"},
      {"referenceName": "chr12", "start": 30500000, "end": 33200000, "cytoband": "p11.21", "stain": "gneg"},
      {"referenceName": "chr12", "start": 33200000, "end": 35500000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr12", "start": 35500000, "end": 37800000, "cytoband": "q11", "stain": "acen"},
      {"referenceName": "chr12", "start": 37800000, "end": 46000000, "cytoband": "q12", "stain": "gpos100"},
      {"referenceName": "chr12", "start": 46000000, "end": 48700000, "cytoband": "q13.11", "stain": "gneg"},
      {"referenceName": "chr12", "start": 48700000, "end": 51100000, "cytoband": "q13.12", "stain": "gpos25"},
      {"referenceName": "chr12", "start": 51100000, "end": 54500000, "cytoband": "q13.13", "stain": "gneg"},
      {"referenceName": "chr12", "start": 54500000, "end": 56200000, "cytoband": "q13.2", "stain": "gpos25"},
      {"referenceName": "chr12", "start": 56200000, "end": 57700000, "cytoband": "q13.3", "stain": "gneg"},
      {"referenceName": "chr12", "start": 57700000, "end": 62700000, "cytoband": "q14.1", "stain": "gpos75"},
      {"referenceName": "chr12", "start": 62700000, "end": 64700000, "cytoband": "q14.2", "stain": "gneg"},
      {"referenceName": "chr12", "start": 64700000, "end": 67300000, "cytoband": "q14.3", "stain": "gpos50"},
      {"referenceName": "chr12", "start": 67300000, "end": 71100000, "cytoband": "q15", "stain": "gneg"},
      {"referenceName": "chr12", "start": 71100000, "end": 75300000, "cytoband": "q21.1", "stain": "gpos75"},
      {"referenceName": "chr12", "start": 75300000, "end": 79900000, "cytoband": "q21.2", "stain": "gneg"},
      {"referenceName": "chr12", "start": 79900000, "end": 86300000, "cytoband": "q21.31", "stain": "gpos100"},
      {"referenceName": "chr12", "start": 86300000, "end": 88600000, "cytoband": "q21.32", "stain": "gneg"},
      {"referenceName": "chr12", "start": 88600000, "end": 92200000, "cytoband": "q21.33", "stain": "gpos100"},
      {"referenceName": "chr12", "start": 92200000, "end": 95800000, "cytoband": "q22", "stain": "gneg"},
      {"referenceName": "chr12", "start": 95800000, "end": 101200000, "cytoband": "q23.1", "stain": "gpos75"},
      {"referenceName": "chr12", "start": 101200000, "end": 103500000, "cytoband": "q23.2", "stain": "gneg"},
      {"referenceName": "chr12", "start": 103500000, "end": 108600000, "cytoband": "q23.3", "stain": "gpos50"},
      {"referenceName": "chr12", "start": 108600000, "end": 111300000, "cytoband": "q24.11", "stain": "gneg"},
      {"referenceName": "chr12", "start": 111300000, "end": 111900000, "cytoband": "q24.12", "stain": "gpos25"},
      {"referenceName": "chr12", "start": 111900000, "end": 113900000, "cytoband": "q24.13", "stain": "gneg"},
      {"referenceName": "chr12", "start": 113900000, "end": 116400000, "cytoband": "q24.21", "stain": "gpos50"},
      {"referenceName": "chr12", "start": 116400000, "end": 117700000, "cytoband": "q24.22", "stain": "gneg"},
      {"referenceName": "chr12", "start": 117700000, "end": 120300000, "cytoband": "q24.23", "stain": "gpos50"},
      {"referenceName": "chr12", "start": 120300000, "end": 125400000, "cytoband": "q24.31", "stain": "gneg"},
      {"referenceName": "chr12", "start": 125400000, "end": 128700000, "cytoband": "q24.32", "stain": "gpos50"},
      {"referenceName": "chr12", "start": 128700000, "end": 133275309, "cytoband": "q24.33", "stain": "gneg"}
    ],
    "chr13": [
      {"referenceName": "chr13", "start": 0, "end": 4600000, "cytoband": "p13", "stain": "gvar"},
      {"referenceName": "chr13", "start": 4600000, "end": 10100000, "cytoband": "p12", "stain": "stalk"},
      {"referenceName": "chr13", "start": 10100000, "end": 16500000, "cytoband": "p11.2", "stain": "gvar"},
      {"referenceName": "chr13", "start": 16500000, "end": 17700000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr13", "start": 17700000, "end": 18900000, "cytoband": "q11", "stain": "acen"},
      {"referenceName": "chr13", "start": 18900000, "end": 22600000, "cytoband": "q12.11", "stain": "gneg"},
      {"referenceName": "chr13", "start": 22600000, "end": 24900000, "cytoband": "q12.12", "stain": "gpos25"},
      {"referenceName": "chr13", "start": 24900000, "end": 27200000, "cytoband": "q12.13", "stain": "gneg"},
      {"referenceName": "chr13", "start": 27200000, "end": 28300000, "cytoband": "q12.2", "stain": "gpos25"},
      {"referenceName": "chr13", "start": 28300000, "end": 31600000, "cytoband": "q12.3", "stain": "gneg"},
      {"referenceName": "chr13", "start": 31600000, "end": 33400000, "cytoband": "q13.1", "stain": "gpos50"},
      {"referenceName": "chr13", "start": 33400000, "end": 34900000, "cytoband": "q13.2", "stain": "gneg"},
      {"referenceName": "chr13", "start": 34900000, "end": 39500000, "cytoband": "q13.3", "stain": "gpos75"},
      {"referenceName": "chr13", "start": 39500000, "end": 44600000, "cytoband": "q14.11", "stain": "gneg"},
      {"referenceName": "chr13", "start": 44600000, "end": 45200000, "cytoband": "q14.12", "stain": "gpos25"},
      {"referenceName": "chr13", "start": 45200000, "end": 46700000, "cytoband": "q14.13", "stain": "gneg"},
      {"referenceName": "chr13", "start": 46700000, "end": 50300000, "cytoband": "q14.2", "stain": "gpos50"},
      {"referenceName": "chr13", "start": 50300000, "end": 54700000, "cytoband": "q14.3", "stain": "gneg"},
      {"referenceName": "chr13", "start": 54700000, "end": 59000000, "cytoband": "q21.1", "stain": "gpos100"},
      {"referenceName": "chr13", "start": 59000000, "end": 61800000, "cytoband": "q21.2", "stain": "gneg"},
      {"referenceName": "chr13", "start": 61800000, "end": 65200000, "cytoband": "q21.31", "stain": "gpos75"},
      {"referenceName": "chr13", "start": 65200000, "end": 68100000, "cytoband": "q21.32", "stain": "gneg"},
      {"referenceName": "chr13", "start": 68100000, "end": 72800000, "cytoband": "q21.33", "stain": "gpos100"},
      {"referenceName": "chr13", "start": 72800000, "end": 74900000, "cytoband": "q22.1", "stain": "gneg"},
      {"referenceName": "chr13", "start": 74900000, "end": 76700000, "cytoband": "q22.2", "stain": "gpos50"},
      {"referenceName": "chr13", "start": 76700000, "end": 78500000, "cytoband": "q22.3", "stain": "gneg"},
      {"referenceName": "chr13", "start": 78500000, "end": 87100000, "cytoband": "q31.1", "stain": "gpos100"},
      {"referenceName": "chr13", "start": 87100000, "end": 89400000, "cytoband": "q31.2", "stain": "gneg"},
      {"referenceName": "chr13", "start": 89400000, "end": 94400000, "cytoband": "q31.3", "stain": "gpos100"},
      {"referenceName": "chr13", "start": 94400000, "end": 97500000, "cytoband": "q32.1", "stain": "gneg"},
      {"referenceName": "chr13", "start": 97500000, "end": 98700000, "cytoband": "q32.2", "stain": "gpos25"},
      {"referenceName": "chr13", "start": 98700000, "end": 101100000, "cytoband": "q32.3", "stain": "gneg"},
      {"referenceName": "chr13", "start": 101100000, "end": 104200000, "cytoband": "q33.1", "stain": "gpos100"},
      {"referenceName": "chr13", "start": 104200000, "end": 106400000, "cytoband": "q33.2", "stain": "gneg"},
      {"referenceName": "chr13", "start": 106400000, "end": 109600000, "cytoband": "q33.3", "stain": "gpos100"},
      {"referenceName": "chr13", "start": 109600000, "end": 114364328, "cytoband": "q34", "stain": "gneg"}
    ],
    "chr14": [
      {"referenceName": "chr14", "start": 0, "end": 3600000, "cytoband": "p13", "stain": "gvar"},
      {"referenceName": "chr14", "start": 3600000, "end": 8000000, "cytoband": "p12", "stain": "stalk"},
      {"referenceName": "chr14", "start": 8000000, "end": 16100000, "cytoband": "p11.2", "stain": "gvar"},
      {"referenceName": "chr14", "start": 16100000, "end": 17200000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr14", "start": 17200000, "end": 18200000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr14", "start": 18200000, "end": 24100000, "cytoband": "q11.2", "stain": "gneg"},
      {"referenceName": "chr14", "start": 24100000, "end": 32900000, "cytoband": "q12", "stain": "gpos100"},
      {"referenceName": "chr14", "start": 32900000, "end": 34800000, "cytoband": "q13.1", "stain": "gneg"},
      {"referenceName": "chr14", "start": 34800000, "end": 36100000, "cytoband": "q13.2", "stain": "gpos50"},
      {"referenceName": "chr14", "start": 36100000, "end": 37400000, "cytoband": "q13.3", "stain": "gneg"},
      {"referenceName": "chr14", "start": 37400000, "end": 43000000, "cytoband": "q21.1", "stain": "gpos100"},
      {"referenceName": "chr14", "start": 43000000, "end": 46700000, "cytoband": "q21.2", "stain": "gneg"},
      {"referenceName": "chr14", "start": 46700000, "end": 50400000, "cytoband": "q21.3", "stain": "gpos100"},
      {"referenceName": "chr14", "start": 50400000, "end": 53600000, "cytoband": "q22.1", "stain": "gneg"},
      {"referenceName": "chr14", "start": 53600000, "end": 55000000, "cytoband": "q22.2", "stain": "gpos25"},
      {"referenceName": "chr14", "start": 55000000, "end": 57600000, "cytoband": "q22.3", "stain": "gneg"},
      {"referenceName": "chr14", "start": 57600000, "end": 61600000, "cytoband": "q23.1", "stain": "gpos75"},
      {"referenceName": "chr14", "start": 61600000, "end": 64300000, "cytoband": "q23.2", "stain": "gneg"},
      {"referenceName": "chr14", "start": 64300000, "end": 67400000, "cytoband": "q23.3", "stain": "gpos50"},
      {"referenceName": "chr14", "start": 67400000, "end": 69800000, "cytoband": "q24.1", "stain": "gneg"},
      {"referenceName": "chr14", "start": 69800000, "end": 73300000, "cytoband": "q24.2", "stain": "gpos50"},
      {"referenceName": "chr14", "start": 73300000, "end": 78800000, "cytoband": "q24.3", "stain": "gneg"},
      {"referenceName": "chr14", "start": 78800000, "end": 83100000, "cytoband": "q31.1", "stain": "gpos100"},
      {"referenceName": "chr14", "start": 83100000, "end": 84400000, "cytoband": "q31.2", "stain": "gneg"},
      {"referenceName": "chr14", "start": 84400000, "end": 89300000, "cytoband": "q31.3", "stain": "gpos100"},
      {"referenceName": "chr14", "start": 89300000, "end": 91400000, "cytoband": "q32.11", "stain": "gneg"},
      {"referenceName": "chr14", "start": 91400000, "end": 94200000, "cytoband": "q32.12", "stain": "gpos25"},
      {"referenceName": "chr14", "start": 94200000, "end": 95800000, "cytoband": "q32.13", "stain": "gneg"},
      {"referenceName": "chr14", "start": 95800000, "end": 100900000, "cytoband": "q32.2", "stain": "gpos50"},
      {"referenceName": "chr14", "start": 100900000, "end": 102700000, "cytoband": "q32.31", "stain": "gneg"},
      {"referenceName": "chr14", "start": 102700000, "end": 103500000, "cytoband": "q32.32", "stain": "gpos50"},
      {"referenceName": "chr14", "start": 103500000, "end": 107043718, "cytoband": "q32.33", "stain": "gneg"}
    ],
    "chr15": [
      {"referenceName": "chr15", "start": 0, "end": 4200000, "cytoband": "p13", "stain": "gvar"},
      {"referenceName": "chr15", "start": 4200000, "end": 9700000, "cytoband": "p12", "stain": "stalk"},
      {"referenceName": "chr15", "start": 9700000, "end": 17500000, "cytoband": "p11.2", "stain": "gvar"},
      {"referenceName": "chr15", "start": 17500000, "end": 19000000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr15", "start": 19000000, "end": 20500000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr15", "start": 20500000, "end": 25500000, "cytoband": "q11.2", "stain": "gneg"},
      {"referenceName": "chr15", "start": 25500000, "end": 27800000, "cytoband": "q12", "stain": "gpos50"},
      {"referenceName": "chr15", "start": 27800000, "end": 30000000, "cytoband": "q13.1", "stain": "gneg"},
      {"referenceName": "chr15", "start": 30000000, "end": 30900000, "cytoband": "q13.2", "stain": "gpos50"},
      {"referenceName": "chr15", "start": 30900000, "end": 33400000, "cytoband": "q13.3", "stain": "gneg"},
      {"referenceName": "chr15", "start": 33400000, "end": 39800000, "cytoband": "q14", "stain": "gpos75"},
      {"referenceName": "chr15", "start": 39800000, "end": 42500000, "cytoband": "q15.1", "stain": "gneg"},
      {"referenceName": "chr15", "start": 42500000, "end": 43300000, "cytoband": "q15.2", "stain": "gpos25"},
      {"referenceName": "chr15", "start": 43300000, "end": 44500000, "cytoband": "q15.3", "stain": "gneg"},
      {"referenceName": "chr15", "start": 44500000, "end": 49200000, "cytoband": "q21.1", "stain": "gpos75"},
      {"referenceName": "chr15", "start": 49200000, "end": 52600000, "cytoband": "q21.2", "stain": "gneg"},
      {"referenceName": "chr15", "start": 52600000, "end": 58800000, "cytoband": "q21.3", "stain": "gpos75"},
      {"referenceName": "chr15", "start": 58800000, "end": 59000000, "cytoband": "q22.1", "stain": "gneg"},
      {"referenceName": "chr15", "start": 59000000, "end": 63400000, "cytoband": "q22.2", "stain": "gpos25"},
      {"referenceName": "chr15", "start": 63400000, "end": 66900000, "cytoband": "q22.31", "stain": "gneg"},
      {"referenceName": "chr15", "start": 66900000, "end": 67000000, "cytoband": "q22.32", "stain": "gpos25"},
      {"referenceName": "chr15", "start": 67000000, "end": 67200000, "cytoband": "q22.33", "stain": "gneg"},
      {"referenceName": "chr15", "start": 67200000, "end": 72400000, "cytoband": "q23", "stain": "gpos25"},
      {"referenceName": "chr15", "start": 72400000, "end": 74900000, "cytoband": "q24.1", "stain": "gneg"},
      {"referenceName": "chr15", "start": 74900000, "end": 76300000, "cytoband": "q24.2", "stain": "gpos25"},
      {"referenceName": "chr15", "start": 76300000, "end": 78000000, "cytoband": "q24.3", "stain": "gneg"},
      {"referenceName": "chr15", "start": 78000000, "end": 81400000, "cytoband": "q25.1", "stain": "gpos50"},
      {"referenceName": "chr15", "start": 81400000, "end": 84700000, "cytoband": "q25.2", "stain": "gneg"},
      {"referenceName": "chr15", "start": 84700000, "end": 88500000, "cytoband": "q25.3", "stain": "gpos50"},
      {"referenceName": "chr15", "start": 88500000, "end": 93800000, "cytoband": "q26.1", "stain": "gneg"},
      {"referenceName": "chr15", "start": 93800000, "end": 98000000, "cytoband": "q26.2", "stain": "gpos50"},
      {"referenceName": "chr15", "start": 98000000, "end": 101991189, "cytoband": "q26.3", "stain": "gneg"}
    ],
    "chr16": [
      {"referenceName": "chr16", "start": 0, "end": 7800000, "cytoband": "p13.3", "stain": "gneg"},
      {"referenceName": "chr16", "start": 7800000, "end": 10400000, "cytoband": "p13.2", "stain": "gpos50"},
      {"referenceName": "chr16", "start": 10400000, "end": 12500000, "cytoband": "p13.13", "stain": "gneg"},
      {"referenceName": "chr16", "start": 12500000, "end": 14700000, "cytoband": "p13.12", "stain": "gpos50"},
      {"referenceName": "chr16", "start": 14700000, "end": 16700000, "cytoband": "p13.11", "stain": "gneg"},
      {"referenceName": "chr16", "start": 16700000, "end": 21200000, "cytoband": "p12.3", "stain": "gpos50"},
      {"referenceName": "chr16", "start": 21200000, "end": 24200000, "cytoband": "p12.2", "stain": "gneg"},
      {"referenceName": "chr16", "start": 24200000, "end": 28500000, "cytoband": "p12.1", "stain": "gpos50"},
      {"referenceName": "chr16", "start": 28500000, "end": 35300000, "cytoband": "p11.2", "stain": "gneg"},
      {"referenceName": "chr16", "start": 35300000, "end": 36800000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr16", "start": 36800000, "end": 38400000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr16", "start": 38400000, "end": 47000000, "cytoband": "q11.2", "stain": "gvar"},
      {"referenceName": "chr16", "start": 47000000, "end": 52600000, "cytoband": "q12.1", "stain": "gneg"},
      {"referenceName": "chr16", "start": 52600000, "end": 56000000, "cytoband": "q12.2", "stain": "gpos50"},
      {"referenceName": "chr16", "start": 56000000, "end": 57300000, "cytoband": "q13", "stain": "gneg"},
      {"referenceName": "chr16", "start": 57300000, "end": 66600000, "cytoband": "q21", "stain": "gpos100"},
      {"referenceName": "chr16", "start": 66600000, "end": 70800000, "cytoband": "q22.1", "stain": "gneg"},
      {"referenceName": "chr16", "start": 70800000, "end": 72800000, "cytoband": "q22.2", "stain": "gpos50"},
      {"referenceName": "chr16", "start": 72800000, "end": 74100000, "cytoband": "q22.3", "stain": "gneg"},
      {"referenceName": "chr16", "start": 74100000, "end": 79200000, "cytoband": "q23.1", "stain": "gpos75"},
      {"referenceName": "chr16", "start": 79200000, "end": 81600000, "cytoband": "q23.2", "stain": "gneg"},
      {"referenceName": "chr16", "start": 81600000, "end": 84100000, "cytoband": "q23.3", "stain": "gpos50"},
      {"referenceName": "chr16", "start": 84100000, "end": 87000000, "cytoband": "q24.1", "stain": "gneg"},
      {"referenceName": "chr16", "start": 87000000, "end": 88700000, "cytoband": "q24.2", "stain": "gpos25"},
      {"referenceName": "chr16", "start": 88700000, "end": 90338345, "cytoband": "q24.3", "stain": "gneg"}
    ],
    "chr17": [
      {"referenceName": "chr17", "start": 0, "end": 3400000, "cytoband": "p13.3", "stain": "gneg"},
      {"referenceName": "chr17", "start": 3400000, "end": 6500000, "cytoband": "p13.2", "stain": "gpos50"},
      {"referenceName": "chr17", "start": 6500000, "end": 10800000, "cytoband": "p13.1", "stain": "gneg"},
      {"referenceName": "chr17", "start": 10800000, "end": 16100000, "cytoband": "p12", "stain": "gpos75"},
      {"referenceName": "chr17", "start": 16100000, "end": 22700000, "cytoband": "p11.2", "stain": "gneg"},
      {"referenceName": "chr17", "start": 22700000, "end": 25100000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr17", "start": 25100000, "end": 27400000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr17", "start": 27400000, "end": 33500000, "cytoband": "q11.2", "stain": "gneg"},
      {"referenceName": "chr17", "start": 33500000, "end": 39800000, "cytoband": "q12", "stain": "gpos50"},
      {"referenceName": "chr17", "start": 39800000, "end": 40200000, "cytoband": "q21.1", "stain": "gneg"},
      {"referenceName": "chr17", "start": 40200000, "end": 42800000, "cytoband": "q21.2", "stain": "gpos25"},
      {"referenceName": "chr17", "start": 42800000, "end": 46800000, "cytoband": "q21.31", "stain": "gneg"},
      {"referenceName": "chr17", "start": 46800000, "end": 49300000, "cytoband": "q21.32", "stain": "gpos25"},
      {"referenceName": "chr17", "start": 49300000, "end": 52100000, "cytoband": "q21.33", "stain": "gneg"},
      {"referenceName": "chr17", "start": 52100000, "end": 59500000, "cytoband": "q22", "stain": "gpos75"},
      {"referenceName": "chr17", "start": 59500000, "end": 60200000, "cytoband": "q23.1", "stain": "gneg"},
      {"referenceName": "chr17", "start": 60200000, "end": 63100000, "cytoband": "q23.2", "stain": "gpos75"},
      {"referenceName": "chr17", "start": 63100000, "end": 64600000, "cytoband": "q23.3", "stain": "gneg"},
      {"referenceName": "chr17", "start": 64600000, "end": 66200000, "cytoband": "q24.1", "stain": "gpos50"},
      {"referenceName": "chr17", "start": 66200000, "end": 69100000, "cytoband": "q24.2", "stain": "gneg"},
      {"referenceName": "chr17", "start": 69100000, "end": 72900000, "cytoband": "q24.3", "stain": "gpos75"},
      {"referenceName": "chr17", "start": 72900000, "end": 76800000, "cytoband": "q25.1", "stain": "gneg"},
      {"referenceName": "chr17", "start": 76800000, "end": 77200000, "cytoband": "q25.2", "stain": "gpos25"},
      {"referenceName": "chr17", "start": 77200000, "end": 83257441, "cytoband": "q25.3", "stain": "gneg"}
    ],
    "chr18": [
      {"referenceName": "chr18", "start": 0, "end": 2900000, "cytoband": "p11.32", "stain": "gneg"},
      {"referenceName": "chr18", "start": 2900000, "end": 7200000, "cytoband": "p11.31", "stain": "gpos50"},
      {"referenceName": "chr18", "start": 7200000, "end": 8500000, "cytoband": "p11.23", "stain": "gneg"},
      {"referenceName": "chr18", "start": 8500000, "end": 10900000, "cytoband": "p11.22", "stain": "gpos25"},
      {"referenceName": "chr18", "start": 10900000, "end": 15400000, "cytoband": "p11.21", "stain": "gneg"},
      {"referenceName": "chr18", "start": 15400000, "end": 18500000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr18", "start": 18500000, "end": 21500000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr18", "start": 21500000, "end": 27500000, "cytoband": "q11.2", "stain": "gneg"},
      {"referenceName": "chr18", "start": 27500000, "end": 35100000, "cytoband": "q12.1", "stain": "gpos100"},
      {"referenceName": "chr18", "start": 35100000, "end": 39500000, "cytoband": "q12.2", "stain": "gneg"},
      {"referenceName": "chr18", "start": 39500000, "end": 45900000, "cytoband": "q12.3", "stain": "gpos75"},
      {"referenceName": "chr18", "start": 45900000, "end": 50700000, "cytoband": "q21.1", "stain": "gneg"},
      {"referenceName": "chr18", "start": 50700000, "end": 56200000, "cytoband": "q21.2", "stain": "gpos75"},
      {"referenceName": "chr18", "start": 56200000, "end": 58600000, "cytoband": "q21.31", "stain": "gneg"},
      {"referenceName": "chr18", "start": 58600000, "end": 61300000, "cytoband": "q21.32", "stain": "gpos50"},
      {"referenceName": "chr18", "start": 61300000, "end": 63900000, "cytoband": "q21.33", "stain": "gneg"},
      {"referenceName": "chr18", "start": 63900000, "end": 69100000, "cytoband": "q22.1", "stain": "gpos100"},
      {"referenceName": "chr18", "start": 69100000, "end": 71000000, "cytoband": "q22.2", "stain": "gneg"},
      {"referenceName": "chr18", "start": 71000000, "end": 75400000, "cytoband": "q22.3", "stain": "gpos25"},
      {"referenceName": "chr18", "start": 75400000, "end": 80373285, "cytoband": "q23", "stain": "gneg"}
    ],
    "chr19": [
      {"referenceName": "chr19", "start": 0, "end": 6900000, "cytoband": "p13.3", "stain": "gneg"},
      {"referenceName": "chr19", "start": 6900000, "end": 12600000, "cytoband": "p13.2", "stain": "gpos25"},
      {"referenceName": "chr19", "start": 12600000, "end": 13800000, "cytoband": "p13.13", "stain": "gneg"},
      {"referenceName": "chr19", "start": 13800000, "end": 16100000, "cytoband": "p13.12", "stain": "gpos25"},
      {"referenceName": "chr19", "start": 16100000, "end": 19900000, "cytoband": "p13.11", "stain": "gneg"},
      {"referenceName": "chr19", "start": 19900000, "end": 24200000, "cytoband": "p12", "stain": "gvar"},
      {"referenceName": "chr19", "start": 24200000, "end": 26200000, "cytoband": "p11", "stain": "acen"},
      {"referenceName": "chr19", "start": 26200000, "end": 28100000, "cytoband": "q11", "stain": "acen"},
      {"referenceName": "chr19", "start": 28100000, "end": 31900000, "cytoband": "q12", "stain": "gvar"},
      {"referenceName": "chr19", "start": 31900000, "end": 35100000, "cytoband": "q13.11", "stain": "gneg"},
      {"referenceName": "chr19", "start": 35100000, "end": 37800000, "cytoband": "q13.12", "stain": "gpos25"},
      {"referenceName": "chr19", "start": 37800000, "end": 38200000, "cytoband": "q13.13", "stain": "gneg"},
      {"referenceName": "chr19", "start": 38200000, "end": 42900000, "cytoband": "q13.2", "stain": "gpos25"},
      {"referenceName": "chr19", "start": 42900000, "end": 44700000, "cytoband": "q13.31", "stain": "gneg"},
      {"referenceName": "chr19", "start": 44700000, "end": 47500000, "cytoband": "q13.32", "stain": "gpos25"},
      {"referenceName": "chr19", "start": 47500000, "end": 50900000, "cytoband": "q13.33", "stain": "gneg"},
      {"referenceName": "chr19", "start": 50900000, "end": 53100000, "cytoband": "q13.41", "stain": "gpos25"},
      {"referenceName": "chr19", "start": 53100000, "end": 55800000, "cytoband": "q13.42", "stain": "gneg"},
      {"referenceName": "chr19", "start": 55800000, "end": 58617616, "cytoband": "q13.43", "stain": "gpos25"}
    ],
    "chr2": [
      {"referenceName": "chr2", "start": 0, "end": 4400000, "cytoband": "p25.3", "stain": "gneg"},
      {"referenceName": "chr2", "start": 4400000, "end": 6900000, "cytoband": "p25.2", "stain": "gpos50"},
      {"referenceName": "chr2", "start": 6900000, "end": 12000000, "cytoband": "p25.1", "stain": "gneg"},
      {"referenceName": "chr2", "start": 12000000, "end": 16500000, "cytoband": "p24.3", "stain": "gpos75"},
      {"referenceName": "chr2", "start": 16500000, "end": 19000000, "cytoband": "p24.2", "stain": "gneg"},
      {"referenceName": "chr2", "start": 19000000, "end": 23800000, "cytoband": "p24.1", "stain": "gpos75"},
      {"referenceName": "chr2", "start": 23800000, "end": 27700000, "cytoband": "p23.3", "stain": "gneg"},
      {"referenceName": "chr2", "start": 27700000, "end": 29800000, "cytoband": "p23.2", "stain": "gpos25"},
      {"referenceName": "chr2", "start": 29800000, "end": 31800000, "cytoband": "p23.1", "stain": "gneg"},
      {"referenceName": "chr2", "start": 31800000, "end": 36300000, "cytoband": "p22.3", "stain": "gpos75"},
      {"referenceName": "chr2", "start": 36300000, "end": 38300000, "cytoband": "p22.2", "stain": "gneg"},
      {"referenceName": "chr2", "start": 38300000, "end": 41500000, "cytoband": "p22.1", "stain": "gpos50"},
      {"referenceName": "chr2", "start": 41500000, "end": 47500000, "cytoband": "p21", "stain": "gneg"},
      {"referenceName": "chr2", "start": 47500000, "end": 52600000, "cytoband": "p16.3", "stain": "gpos100"},
      {"referenceName": "chr2", "start": 52600000, "end": 54700000, "cytoband": "p16.2", "stain": "gneg"},
      {"referenceName": "chr2", "start": 54700000, "end": 61000000, "cytoband": "p16.1", "stain": "gpos100"},
      {"referenceName": "chr2", "start": 61000000, "end": 63900000, "cytoband": "p15", "stain": "gneg"},
      {"referenceName": "chr2", "start": 63900000, "end": 68400000, "cytoband": "p14", "stain": "gpos50"},
      {"referenceName": "chr2", "start": 68400000, "end": 71300000, "cytoband": "p13.3", "stain": "gneg"},
      {"referenceName": "chr2", "start": 71300000, "end": 73300000, "cytoband": "p13.2", "stain": "gpos50"},
      {"referenceName": "chr2", "start": 73300000, "end": 74800000, "cytoband": "p13.1", "stain": "gneg"},
      {"referenceName": "chr2", "start": 74800000, "end": 83100000, "cytoband": "p12", "stain": "gpos100"},
      {"referenceName": "chr2", "start": 83100000, "end": 91800000, "cytoband": "p11.2", "stain": "gneg"},
      {"referenceName": "chr2", "start": 91800000, "end": 93900000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr2", "start": 93900000, "end": 96000000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr2", "start": 96000000, "end": 102100000, "cytoband": "q11.2", "stain": "gneg"},
      {"referenceName": "chr2", "start": 102100000, "end": 105300000, "cytoband": "q12.1", "stain": "gpos50"},
      {"referenceName": "chr2", "start": 105300000, "end": 106700000, "cytoband": "q12.2", "stain": "gneg"},
      {"referenceName": "chr2", "start": 106700000, "end": 108700000, "cytoband": "q12.3", "stain": "gpos25"},
      {"referenceName": "chr2", "start": 108700000, "end": 112200000, "cytoband": "q13", "stain": "gneg"},
      {"referenceName": "chr2", "start": 112200000, "end": 118100000, "cytoband": "q14.1", "stain": "gpos50"},
      {"referenceName": "chr2", "start": 118100000, "end": 121600000, "cytoband": "q14.2", "stain": "gneg"},
      {"referenceName": "chr2", "start": 121600000, "end": 129100000, "cytoband": "q14.3", "stain": "gpos50"},
      {"referenceName": "chr2", "start": 129100000, "end": 131700000, "cytoband": "q21.1", "stain": "gneg"},
      {"referenceName": "chr2", "start": 131700000, "end": 134300000, "cytoband": "q21.2", "stain": "gpos25"},
      {"referenceName": "chr2", "start": 134300000, "end": 136100000, "cytoband": "q21.3", "stain": "gneg"},
      {"referenceName": "chr2", "start": 136100000, "end": 141500000, "cytoband": "q22.1", "stain": "gpos100"},
      {"referenceName": "chr2", "start": 141500000, "end": 143400000, "cytoband": "q22.2", "stain": "gneg"},
      {"referenceName": "chr2", "start": 143400000, "end": 147900000, "cytoband": "q22.3", "stain": "gpos100"},
      {"referenceName": "chr2", "start": 147900000, "end": 149000000, "cytoband": "q23.1", "stain": "gneg"},
      {"referenceName": "chr2", "start": 149000000, "end": 149600000, "cytoband": "q23.2", "stain": "gpos25"},
      {"referenceName": "chr2", "start": 149600000, "end": 154000000, "cytoband": "q23.3", "stain": "gneg"},
      {"referenceName": "chr2", "start": 154000000, "end": 158900000, "cytoband": "q24.1", "stain": "gpos75"},
      {"referenceName": "chr2", "start": 158900000, "end": 162900000, "cytoband": "q24.2", "stain": "gneg"},
      {"referenceName": "chr2", "start": 162900000, "end": 168900000, "cytoband": "q24.3", "stain": "gpos75"},
      {"referenceName": "chr2", "start": 168900000, "end": 177100000, "cytoband": "q31.1", "stain": "gneg"},
      {"referenceName": "chr2", "start": 177100000, "end": 179700000, "cytoband": "q31.2", "stain": "gpos50"},
      {"referenceName": "chr2", "start": 179700000, "end": 182100000, "cytoband": "q31.3", "stain": "gneg"},
      {"referenceName": "chr2", "start": 182100000, "end": 188500000, "cytoband": "q32.1", "stain": "gpos75"},
      {"referenceName": "chr2", "start": 188500000, "end": 191100000, "cytoband": "q32.2", "stain": "gneg"},
      {"referenceName": "chr2", "start": 191100000, "end": 196600000, "cytoband": "q32.3", "stain": "gpos75"},
      {"referenceName": "chr2", "start": 196600000, "end": 202500000, "cytoband": "q33.1", "stain": "gneg"},
      {"referenceName": "chr2", "start": 202500000, "end": 204100000, "cytoband": "q33.2", "stain": "gpos50"},
      {"referenceName": "chr2", "start": 204100000, "end": 208200000, "cytoband": "q33.3", "stain": "gneg"},
      {"referenceName": "chr2", "start": 208200000, "end": 214500000, "cytoband": "q34", "stain": "gpos100"},
      {"referenceName": "chr2", "start": 214500000, "end": 220700000, "cytoband": "q35", "stain": "gneg"},
      {"referenceName": "chr2", "start": 220700000, "end": 224300000, "cytoband": "q36.1", "stain": "gpos75"},
      {"referenceName": "chr2", "start": 224300000, "end": 225200000, "cytoband": "q36.2", "stain": "gneg"},
      {"referenceName": "chr2", "start": 225200000, "end": 230100000, "cytoband": "q36.3", "stain": "gpos100"},
      {"referenceName": "chr2", "start": 230100000, "end": 234700000, "cytoband": "q37.1", "stain": "gneg"},
      {"referenceName": "chr2", "start": 234700000, "end": 236400000, "cytoband": "q37.2", "stain": "gpos50"},
      {"referenceName": "chr2", "start": 236400000, "end": 242193529, "cytoband": "q37.3", "stain": "gneg"}
    ],
    "chr20": [
      {"referenceName": "chr20", "start": 0, "end": 5100000, "cytoband": "p13", "stain": "gneg"},
      {"referenceName": "chr20", "start": 5100000, "end": 9200000, "cytoband": "p12.3", "stain": "gpos75"},
      {"referenceName": "chr20", "start": 9200000, "end": 12000000, "cytoband": "p12.2", "stain": "gneg"},
      {"referenceName": "chr20", "start": 12000000, "end": 17900000, "cytoband": "p12.1", "stain": "gpos75"},
      {"referenceName": "chr20", "start": 17900000, "end": 21300000, "cytoband": "p11.23", "stain": "gneg"},
      {"referenceName": "chr20", "start": 21300000, "end": 22300000, "cytoband": "p11.22", "stain": "gpos25"},
      {"referenceName": "chr20", "start": 22300000, "end": 25700000, "cytoband": "p11.21", "stain": "gneg"},
      {"referenceName": "chr20", "start": 25700000, "end": 28100000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr20", "start": 28100000, "end": 30400000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr20", "start": 30400000, "end": 33500000, "cytoband": "q11.21", "stain": "gneg"},
      {"referenceName": "chr20", "start": 33500000, "end": 35800000, "cytoband": "q11.22", "stain": "gpos25"},
      {"referenceName": "chr20", "start": 35800000, "end": 39000000, "cytoband": "q11.23", "stain": "gneg"},
      {"referenceName": "chr20", "start": 39000000, "end": 43100000, "cytoband": "q12", "stain": "gpos75"},
      {"referenceName": "chr20", "start": 43100000, "end": 43500000, "cytoband": "q13.11", "stain": "gneg"},
      {"referenceName": "chr20", "start": 43500000, "end": 47800000, "cytoband": "q13.12", "stain": "gpos25"},
      {"referenceName": "chr20", "start": 47800000, "end": 51200000, "cytoband": "q13.13", "stain": "gneg"},
      {"referenceName": "chr20", "start": 51200000, "end": 56400000, "cytoband": "q13.2", "stain": "gpos75"},
      {"referenceName": "chr20", "start": 56400000, "end": 57800000, "cytoband": "q13.31", "stain": "gneg"},
      {"referenceName": "chr20", "start": 57800000, "end": 59700000, "cytoband": "q13.32", "stain": "gpos50"},
      {"referenceName": "chr20", "start": 59700000, "end": 64444167, "cytoband": "q13.33", "stain": "gneg"}
    ],
    "chr21": [
      {"referenceName": "chr21", "start": 0, "end": 3100000, "cytoband": "p13", "stain": "gvar"},
      {"referenceName": "chr21", "start": 3100000, "end": 7000000, "cytoband": "p12", "stain": "stalk"},
      {"referenceName": "chr21", "start": 7000000, "end": 10900000, "cytoband": "p11.2", "stain": "gvar"},
      {"referenceName": "chr21", "start": 10900000, "end": 12000000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr21", "start": 12000000, "end": 13000000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr21", "start": 13000000, "end": 15000000, "cytoband": "q11.2", "stain": "gneg"},
      {"referenceName": "chr21", "start": 15000000, "end": 22600000, "cytoband": "q21.1", "stain": "gpos100"},
      {"referenceName": "chr21", "start": 22600000, "end": 25500000, "cytoband": "q21.2", "stain": "gneg"},
      {"referenceName": "chr21", "start": 25500000, "end": 30200000, "cytoband": "q21.3", "stain": "gpos75"},
      {"referenceName": "chr21", "start": 30200000, "end": 34400000, "cytoband": "q22.11", "stain": "gneg"},
      {"referenceName": "chr21", "start": 34400000, "end": 36400000, "cytoband": "q22.12", "stain": "gpos50"},
      {"referenceName": "chr21", "start": 36400000, "end": 38300000, "cytoband": "q22.13", "stain": "gneg"},
      {"referenceName": "chr21", "start": 38300000, "end": 41200000, "cytoband": "q22.2", "stain": "gpos50"},
      {"referenceName": "chr21", "start": 41200000, "end": 46709983, "cytoband": "q22.3", "stain": "gneg"}
    ],
    "chr22": [
      {"referenceName": "chr22", "start": 0, "end": 4300000, "cytoband": "p13", "stain": "gvar"},
      {"referenceName": "chr22", "start": 4300000, "end": 9400000, "cytoband": "p12", "stain": "stalk"},
      {"referenceName": "chr22", "start": 9400000, "end": 13700000, "cytoband": "p11.2", "stain": "gvar"},
      {"referenceName": "chr22", "start": 13700000, "end": 15000000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr22", "start": 15000000, "end": 17400000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr22", "start": 17400000, "end": 21700000, "cytoband": "q11.21", "stain": "gneg"},
      {"referenceName": "chr22", "start": 21700000, "end": 23100000, "cytoband": "q11.22", "stain": "gpos25"},
      {"referenceName": "chr22", "start": 23100000, "end": 25500000, "cytoband": "q11.23", "stain": "gneg"},
      {"referenceName": "chr22", "start": 25500000, "end": 29200000, "cytoband": "q12.1", "stain": "gpos50"},
      {"referenceName": "chr22", "start": 29200000, "end": 31800000, "cytoband": "q12.2", "stain": "gneg"},
      {"referenceName": "chr22", "start": 31800000, "end": 37200000, "cytoband": "q12.3", "stain": "gpos50"},
      {"referenceName": "chr22", "start": 37200000, "end": 40600000, "cytoband": "q13.1", "stain": "gneg"},
      {"referenceName": "chr22", "start": 40600000, "end": 43800000, "cytoband": "q13.2", "stain": "gpos50"},
      {"referenceName": "chr22", "start": 43800000, "end": 48100000, "cytoband": "q13.31", "stain": "gneg"},
      {"referenceName": "chr22", "start": 48100000, "end": 49100000, "cytoband": "q13.32", "stain": "gpos50"},
      {"referenceName": "chr22", "start": 49100000, "end": 50818468, "cytoband": "q13.33", "stain": "gneg"}
    ],
    "chr3": [
      {"referenceName": "chr3", "start": 0, "end": 2800000, "cytoband": "p26.3", "stain": "gpos50"},
      {"referenceName": "chr3", "start": 2800000, "end": 4000000, "cytoband": "p26.2", "stain": "gneg"},
      {"referenceName": "chr3", "start": 4000000, "end": 8100000, "cytoband": "p26.1", "stain": "gpos50"},
      {"referenceName": "chr3", "start": 8100000, "end": 11600000, "cytoband": "p25.3", "stain": "gneg"},
      {"referenceName": "chr3", "start": 11600000, "end": 13200000, "cytoband": "p25.2", "stain": "gpos25"},
      {"referenceName": "chr3", "start": 13200000, "end": 16300000, "cytoband": "p25.1", "stain": "gneg"},
      {"referenceName": "chr3", "start": 16300000, "end": 23800000, "cytoband": "p24.3", "stain": "gpos100"},
      {"referenceName": "chr3", "start": 23800000, "end": 26300000, "cytoband": "p24.2", "stain": "gneg"},
      {"referenceName": "chr3", "start": 26300000, "end": 30800000, "cytoband": "p24.1", "stain": "gpos75"},
      {"referenceName": "chr3", "start": 30800000, "end": 32000000, "cytoband": "p23", "stain": "gneg"},
      {"referenceName": "chr3", "start": 32000000, "end": 36400000, "cytoband": "p22.3", "stain": "gpos50"},
      {"referenceName": "chr3", "start": 36400000, "end": 39300000, "cytoband": "p22.2", "stain": "gneg"},
      {"referenceName": "chr3", "start": 39300000, "end": 43600000, "cytoband": "p22.1", "stain": "gpos75"},
      {"referenceName": "chr3", "start": 43600000, "end": 44100000, "cytoband": "p21.33", "stain": "gneg"},
      {"referenceName": "chr3", "start": 44100000, "end": 44200000, "cytoband": "p21.32", "stain": "gpos50"},
      {"referenceName": "chr3", "start": 44200000, "end": 50600000, "cytoband": "p21.31", "stain": "gneg"},
      {"referenceName": "chr3", "start": 50600000, "end": 52300000, "cytoband": "p21.2", "stain": "gpos25"},
      {"referenceName": "chr3", "start": 52300000, "end": 54400000, "cytoband": "p21.1", "stain": "gneg"},
      {"referenceName": "chr3", "start": 54400000, "end": 58600000, "cytoband": "p14.3", "stain": "gpos50"},
      {"referenceName": "chr3", "start": 58600000, "end": 63800000, "cytoband": "p14.2", "stain": "gneg"},
      {"referenceName": "chr3", "start": 63800000, "end": 69700000, "cytoband": "p14.1", "stain": "gpos50"},
      {"referenceName": "chr3", "start": 69700000, "end": 74100000, "cytoband": "p13", "stain": "gneg"},
      {"referenceName": "chr3", "start": 74100000, "end": 79800000, "cytoband": "p12.3", "stain": "gpos75"},
      {"referenceName": "chr3", "start": 79800000, "end": 83500000, "cytoband": "p12.2", "stain": "gneg"},
      {"referenceName": "chr3", "start": 83500000, "end": 87100000, "cytoband": "p12.1", "stain": "gpos75"},
      {"referenceName": "chr3", "start": 87100000, "end": 87800000, "cytoband": "p11.2", "stain": "gneg"},
      {"referenceName": "chr3", "start": 87800000, "end": 90900000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr3", "start": 90900000, "end": 94000000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr3", "start": 94000000, "end": 98600000, "cytoband": "q11.2", "stain": "gvar"},
      {"referenceName": "chr3", "start": 98600000, "end": 100300000, "cytoband": "q12.1", "stain": "gneg"},
      {"referenceName": "chr3", "start": 100300000, "end": 101200000, "cytoband": "q12.2", "stain": "gpos25"},
      {"referenceName": "chr3", "start": 101200000, "end": 103100000, "cytoband": "q12.3", "stain": "gneg"},
      {"referenceName": "chr3", "start": 103100000, "end": 106500000, "cytoband": "q13.11", "stain": "gpos75"},
      {"referenceName": "chr3", "start": 106500000, "end": 108200000, "cytoband": "q13.12", "stain": "gneg"},
      {"referenceName": "chr3", "start": 108200000, "end": 111600000, "cytoband": "q13.13", "stain": "gpos50"},
      {"referenceName": "chr3", "start": 111600000, "end": 113700000, "cytoband": "q13.2", "stain": "gneg"},
      {"referenceName": "chr3", "start": 113700000, "end": 117600000, "cytoband": "q13.31", "stain": "gpos75"},
      {"referenceName": "chr3", "start": 117600000, "end": 119300000, "cytoband": "q13.32", "stain": "gneg"},
      {"referenceName": "chr3", "start": 119300000, "end": 122200000, "cytoband": "q13.33", "stain": "gpos75"},
      {"referenceName": "chr3", "start": 122200000, "end": 124100000, "cytoband": "q21.1", "stain": "gneg"},
      {"referenceName": "chr3", "start": 124100000, "end": 126100000, "cytoband": "q21.2", "stain": "gpos25"},
      {"referenceName": "chr3", "start": 126100000, "end": 129500000, "cytoband": "q21.3", "stain": "gneg"},
      {"referenceName": "chr3", "start": 129500000, "end": 134000000, "cytoband": "q22.1", "stain": "gpos25"},
      {"referenceName": "chr3", "start": 134000000, "end": 136000000, "cytoband": "q22.2", "stain": "gneg"},
      {"referenceName": "chr3", "start": 136000000, "end": 139000000, "cytoband": "q22.3", "stain": "gpos25"},
      {"referenceName": "chr3", "start": 139000000, "end": 143100000, "cytoband": "q23", "stain": "gneg"},
      {"referenceName": "chr3", "start": 143100000, "end": 149200000, "cytoband": "q24", "stain": "gpos100"},
      {"referenceName": "chr3", "start": 149200000, "end": 152300000, "cytoband": "q25.1", "stain": "gneg"},
      {"referenceName": "chr3", "start": 152300000, "end": 155300000, "cytoband": "q25.2", "stain": "gpos50"},
      {"referenceName": "chr3", "start": 155300000, "end": 157300000, "cytoband": "q25.31", "stain": "gneg"},
      {"referenceName": "chr3", "start": 157300000, "end": 159300000, "cytoband": "q25.32", "stain": "gpos50"},
      {"referenceName": "chr3", "start": 159300000, "end": 161000000, "cytoband": "q25.33", "stain": "gneg"},
      {"referenceName": "chr3", "start": 161000000, "end": 167900000, "cytoband": "q26.1", "stain": "gpos100"},
      {"referenceName": "chr3", "start": 167900000, "end": 171200000, "cytoband": "q26.2", "stain": "gneg"},
      {"referenceName": "chr3", "start": 171200000, "end": 176000000, "cytoband": "q26.31", "stain": "gpos75"},
      {"referenceName": "chr3", "start": 176000000, "end": 179300000, "cytoband": "q26.32", "stain": "gneg"},
      {"referenceName": "chr3", "start": 179300000, "end": 183000000, "cytoband": "q26.33", "stain": "gpos75"},
      {"referenceName": "chr3", "start": 183000000, "end": 184800000, "cytoband": "q27.1", "stain": "gneg"},
      {"referenceName": "chr3", "start": 184800000, "end": 186300000, "cytoband": "q27.2", "stain": "gpos25"},
      {"referenceName": "chr3", "start": 186300000, "end": 188200000, "cytoband": "q27.3", "stain": "gneg"},
      {"referenceName": "chr3", "start": 188200000, "end": 192600000, "cytoband": "q28", "stain": "gpos75"},
      {"referenceName": "chr3", "start": 192600000, "end": 198295559, "cytoband": "q29", "stain": "gneg"}
    ],
    "chr4": [
      {"referenceName": "chr4", "start": 0, "end": 4500000, "cytoband": "p16.3", "stain": "gneg"},
      {"referenceName": "chr4", "start": 4500000, "end": 6000000, "cytoband": "p16.2", "stain": "gpos25"},
      {"referenceName": "chr4", "start": 6000000, "end": 11300000, "cytoband": "p16.1", "stain": "gneg"},
      {"referenceName": "chr4", "start": 11300000, "end": 15000000, "cytoband": "p15.33", "stain": "gpos50"},
      {"referenceName": "chr4", "start": 15000000, "end": 17700000, "cytoband": "p15.32", "stain": "gneg"},
      {"referenceName": "chr4", "start": 17700000, "end": 21300000, "cytoband": "p15.31", "stain": "gpos75"},
      {"referenceName": "chr4", "start": 21300000, "end": 27700000, "cytoband": "p15.2", "stain": "gneg"},
      {"referenceName": "chr4", "start": 27700000, "end": 35800000, "cytoband": "p15.1", "stain": "gpos100"},
      {"referenceName": "chr4", "start": 35800000, "end": 41200000, "cytoband": "p14", "stain": "gneg"},
      {"referenceName": "chr4", "start": 41200000, "end": 44600000, "cytoband": "p13", "stain": "gpos50"},
      {"referenceName": "chr4", "start": 44600000, "end": 48200000, "cytoband": "p12", "stain": "gneg"},
      {"referenceName": "chr4", "start": 48200000, "end": 50000000, "cytoband": "p11", "stain": "acen"},
      {"referenceName": "chr4", "start": 50000000, "end": 51800000, "cytoband": "q11", "stain": "acen"},
      {"referenceName": "chr4", "start": 51800000, "end": 58500000, "cytoband": "q12", "stain": "gneg"},
      {"referenceName": "chr4", "start": 58500000, "end": 65500000, "cytoband": "q13.1", "stain": "gpos100"},
      {"referenceName": "chr4", "start": 65500000, "end": 69400000, "cytoband": "q13.2", "stain": "gneg"},
      {"referenceName": "chr4", "start": 69400000, "end": 75300000, "cytoband": "q13.3", "stain": "gpos75"},
      {"referenceName": "chr4", "start": 75300000, "end": 78000000, "cytoband": "q21.1", "stain": "gneg"},
      {"referenceName": "chr4", "start": 78000000, "end": 81500000, "cytoband": "q21.21", "stain": "gpos50"},
      {"referenceName": "chr4", "start": 81500000, "end": 83200000, "cytoband": "q21.22", "stain": "gneg"},
      {"referenceName": "chr4", "start": 83200000, "end": 86000000, "cytoband": "q21.23", "stain": "gpos25"},
      {"referenceName": "chr4", "start": 86000000, "end": 87100000, "cytoband": "q21.3", "stain": "gneg"},
      {"referenceName": "chr4", "start": 87100000, "end": 92800000, "cytoband": "q22.1", "stain": "gpos75"},
      {"referenceName": "chr4", "start": 92800000, "end": 94200000, "cytoband": "q22.2", "stain": "gneg"},
      {"referenceName": "chr4", "start": 94200000, "end": 97900000, "cytoband": "q22.3", "stain": "gpos75"},
      {"referenceName": "chr4", "start": 97900000, "end": 100100000, "cytoband": "q23", "stain": "gneg"},
      {"referenceName": "chr4", "start": 100100000, "end": 106700000, "cytoband": "q24", "stain": "gpos50"},
      {"referenceName": "chr4", "start": 106700000, "end": 113200000, "cytoband": "q25", "stain": "gneg"},
      {"referenceName": "chr4", "start": 113200000, "end": 119900000, "cytoband": "q26", "stain": "gpos75"},
      {"referenceName": "chr4", "start": 119900000, "end": 122800000, "cytoband": "q27", "stain": "gneg"},
      {"referenceName": "chr4", "start": 122800000, "end": 127900000, "cytoband": "q28.1", "stain": "gpos50"},
      {"referenceName": "chr4", "start": 127900000, "end": 130100000, "cytoband": "q28.2", "stain": "gneg"},
      {"referenceName": "chr4", "start": 130100000, "end": 138500000, "cytoband": "q28.3", "stain": "gpos100"},
      {"referenceName": "chr4", "start": 138500000, "end": 140600000, "cytoband": "q31.1", "stain": "gneg"},
      {"referenceName": "chr4", "start": 140600000, "end": 145900000, "cytoband": "q31.21", "stain": "gpos25"},
      {"referenceName": "chr4", "start": 145900000, "end": 147500000, "cytoband": "q31.22", "stain": "gneg"},
      {"referenceName": "chr4", "start": 147500000, "end": 150200000, "cytoband": "q31.23", "stain": "gpos25"},
      {"referenceName": "chr4", "start": 150200000, "end": 154600000, "cytoband": "q31.3", "stain": "gneg"},
      {"referenceName": "chr4", "start": 154600000, "end": 160800000, "cytoband": "q32.1", "stain": "gpos100"},
      {"referenceName": "chr4", "start": 160800000, "end": 163600000, "cytoband": "q32.2", "stain": "gneg"},
      {"referenceName": "chr4", "start": 163600000, "end": 169200000, "cytoband": "q32.3", "stain": "gpos100"},
      {"referenceName": "chr4", "start": 169200000, "end": 171000000, "cytoband": "q33", "stain": "gneg"},
      {"referenceName": "chr4", "start": 171000000, "end": 175400000, "cytoband": "q34.1", "stain": "gpos75"},
      {"referenceName": "chr4", "start": 175400000, "end": 176600000, "cytoband": "q34.2", "stain": "gneg"},
      {"referenceName": "chr4", "start": 176600000, "end": 182300000, "cytoband": "q34.3", "stain": "gpos100"},
      {"referenceName": "chr4", "start": 182300000, "end": 186200000, "cytoband": "q35.1", "stain": "gneg"},
      {"referenceName": "chr4", "start": 186200000, "end": 190214555, "cytoband": "q35.2", "stain": "gpos25"}
    ],
    "chr5": [
      {"referenceName": "chr5", "start": 0, "end": 4400000, "cytoband": "p15.33", "stain": "gneg"},
      {"referenceName": "chr5", "start": 4400000, "end": 6300000, "cytoband": "p15.32", "stain": "gpos25"},
      {"referenceName": "chr5", "start": 6300000, "end": 9900000, "cytoband": "p15.31", "stain": "gneg"},
      {"referenceName": "chr5", "start": 9900000, "end": 15000000, "cytoband": "p15.2", "stain": "gpos50"},
      {"referenceName": "chr5", "start": 15000000, "end": 18400000, "cytoband": "p15.1", "stain": "gneg"},
      {"referenceName": "chr5", "start": 18400000, "end": 23300000, "cytoband": "p14.3", "stain": "gpos100"},
      {"referenceName": "chr5", "start": 23300000, "end": 24600000, "cytoband": "p14.2", "stain": "gneg"},
      {"referenceName": "chr5", "start": 24600000, "end": 28900000, "cytoband": "p14.1", "stain": "gpos100"},
      {"referenceName": "chr5", "start": 28900000, "end": 33800000, "cytoband": "p13.3", "stain": "gneg"},
      {"referenceName": "chr5", "start": 33800000, "end": 38400000, "cytoband": "p13.2", "stain": "gpos25"},
      {"referenceName": "chr5", "start": 38400000, "end": 42500000, "cytoband": "p13.1", "stain": "gneg"},
      {"referenceName": "chr5", "start": 42500000, "end": 46100000, "cytoband": "p12", "stain": "gpos50"},
      {"referenceName": "chr5", "start": 46100000, "end": 48800000, "cytoband": "p11", "stain": "acen"},
      {"referenceName": "chr5", "start": 48800000, "end": 51400000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr5", "start": 51400000, "end": 59600000, "cytoband": "q11.2", "stain": "gneg"},
      {"referenceName": "chr5", "start": 59600000, "end": 63600000, "cytoband": "q12.1", "stain": "gpos75"},
      {"referenceName": "chr5", "start": 63600000, "end": 63900000, "cytoband": "q12.2", "stain": "gneg"},
      {"referenceName": "chr5", "start": 63900000, "end": 67400000, "cytoband": "q12.3", "stain": "gpos75"},
      {"referenceName": "chr5", "start": 67400000, "end": 69100000, "cytoband": "q13.1", "stain": "gneg"},
      {"referenceName": "chr5", "start": 69100000, "end": 74000000, "cytoband": "q13.2", "stain": "gpos50"},
      {"referenceName": "chr5", "start": 74000000, "end": 77600000, "cytoband": "q13.3", "stain": "gneg"},
      {"referenceName": "chr5", "start": 77600000, "end": 82100000, "cytoband": "q14.1", "stain": "gpos50"},
      {"referenceName": "chr5", "start": 82100000, "end": 83500000, "cytoband": "q14.2", "stain": "gneg"},
      {"referenceName": "chr5", "start": 83500000, "end": 93000000, "cytoband": "q14.3", "stain": "gpos100"},
      {"referenceName": "chr5", "start": 93000000, "end": 98900000, "cytoband": "q15", "stain": "gneg"},
      {"referenceName": "chr5", "start": 98900000, "end": 103400000, "cytoband": "q21.1", "stain": "gpos100"},
      {"referenceName": "chr5", "start": 103400000, "end": 105100000, "cytoband": "q21.2", "stain": "gneg"},
      {"referenceName": "chr5", "start": 105100000, "end": 110200000, "cytoband": "q21.3", "stain": "gpos100"},
      {"referenceName": "chr5", "start": 110200000, "end": 112200000, "cytoband": "q22.1", "stain": "gneg"},
      {"referenceName": "chr5", "start": 112200000, "end": 113800000, "cytoband": "q22.2", "stain": "gpos50"},
      {"referenceName": "chr5", "start": 113800000, "end": 115900000, "cytoband": "q22.3", "stain": "gneg"},
      {"referenceName": "chr5", "start": 115900000, "end": 122100000, "cytoband": "q23.1", "stain": "gpos100"},
      {"referenceName": "chr5", "start": 122100000, "end": 127900000, "cytoband": "q23.2", "stain": "gneg"},
      {"referenceName": "chr5", "start": 127900000, "end": 131200000, "cytoband": "q23.3", "stain": "gpos100"},
      {"referenceName": "chr5", "start": 131200000, "end": 136900000, "cytoband": "q31.1", "stain": "gneg"},
      {"referenceName": "chr5", "start": 136900000, "end": 140100000, "cytoband": "q31.2", "stain": "gpos25"},
      {"referenceName": "chr5", "start": 140100000, "end": 145100000, "cytoband": "q31.3", "stain": "gneg"},
      {"referenceName": "chr5", "start": 145100000, "end": 150400000, "cytoband": "q32", "stain": "gpos75"},
      {"referenceName": "chr5", "start": 150400000, "end": 153300000, "cytoband": "q33.1", "stain": "gneg"},
      {"referenceName": "chr5", "start": 153300000, "end": 156300000, "cytoband": "q33.2", "stain": "gpos50"},
      {"referenceName": "chr5", "start": 156300000, "end": 160500000, "cytoband": "q33.3", "stain": "gneg"},
      {"referenceName": "chr5", "start": 160500000, "end": 169000000, "cytoband": "q34", "stain": "gpos100"},
      {"referenceName": "chr5", "start": 169000000, "end": 173300000, "cytoband": "q35.1", "stain": "gneg"},
      {"referenceName": "chr5", "start": 173300000, "end": 177100000, "cytoband": "q35.2", "stain": "gpos25"},
      {"referenceName": "chr5", "start": 177100000, "end": 181538259, "cytoband": "q35.3", "stain": "gneg"}
    ],
    "chr6": [
      {"referenceName": "chr6", "start": 0, "end": 2300000, "cytoband": "p25.3", "stain": "gneg"},
      {"referenceName": "chr6", "start": 2300000, "end": 4200000, "cytoband": "p25.2", "stain": "gpos25"},
      {"referenceName": "chr6", "start": 4200000, "end": 7100000, "cytoband": "p25.1", "stain": "gneg"},
      {"referenceName": "chr6", "start": 7100000, "end": 10600000, "cytoband": "p24.3", "stain": "gpos50"},
      {"referenceName": "chr6", "start": 10600000, "end": 11600000, "cytoband": "p24.2", "stain": "gneg"},
      {"referenceName": "chr6", "start": 11600000, "end": 13400000, "cytoband": "p24.1", "stain": "gpos25"},
      {"referenceName": "chr6", "start": 13400000, "end": 15200000, "cytoband": "p23", "stain": "gneg"},
      {"referenceName": "chr6", "start": 15200000, "end": 25200000, "cytoband": "p22.3", "stain": "gpos75"},
      {"referenceName": "chr6", "start": 25200000, "end": 27100000, "cytoband": "p22.2", "stain": "gneg"},
      {"referenceName": "chr6", "start": 27100000, "end": 30500000, "cytoband": "p22.1", "stain": "gpos50"},
      {"referenceName": "chr6", "start": 30500000, "end": 32100000, "cytoband": "p21.33", "stain": "gneg"},
      {"referenceName": "chr6", "start": 32100000, "end": 33500000, "cytoband": "p21.32", "stain": "gpos25"},
      {"referenceName": "chr6", "start": 33500000, "end": 36600000, "cytoband": "p21.31", "stain": "gneg"},
      {"referenceName": "chr6", "start": 36600000, "end": 40500000, "cytoband": "p21.2", "stain": "gpos25"},
      {"referenceName": "chr6", "start": 40500000, "end": 46200000, "cytoband": "p21.1", "stain": "gneg"},
      {"referenceName": "chr6", "start": 46200000, "end": 51800000, "cytoband": "p12.3", "stain": "gpos100"},
      {"referenceName": "chr6", "start": 51800000, "end": 53000000, "cytoband": "p12.2", "stain": "gneg"},
      {"referenceName": "chr6", "start": 53000000, "end": 57200000, "cytoband": "p12.1", "stain": "gpos100"},
      {"referenceName": "chr6", "start": 57200000, "end": 58500000, "cytoband": "p11.2", "stain": "gneg"},
      {"referenceName": "chr6", "start": 58500000, "end": 59800000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr6", "start": 59800000, "end": 62600000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr6", "start": 62600000, "end": 62700000, "cytoband": "q11.2", "stain": "gneg"},
      {"referenceName": "chr6", "start": 62700000, "end": 69200000, "cytoband": "q12", "stain": "gpos100"},
      {"referenceName": "chr6", "start": 69200000, "end": 75200000, "cytoband": "q13", "stain": "gneg"},
      {"referenceName": "chr6", "start": 75200000, "end": 83200000, "cytoband": "q14.1", "stain": "gpos50"},
      {"referenceName": "chr6", "start": 83200000, "end": 84200000, "cytoband": "q14.2", "stain": "gneg"},
      {"referenceName": "chr6", "start": 84200000, "end": 87300000, "cytoband": "q14.3", "stain": "gpos50"},
      {"referenceName": "chr6", "start": 87300000, "end": 92500000, "cytoband": "q15", "stain": "gneg"},
      {"referenceName": "chr6", "start": 92500000, "end": 98900000, "cytoband": "q16.1", "stain": "gpos100"},
      {"referenceName": "chr6", "start": 98900000, "end": 100000000, "cytoband": "q16.2", "stain": "gneg"},
      {"referenceName": "chr6", "start": 100000000, "end": 105000000, "cytoband": "q16.3", "stain": "gpos100"},
      {"referenceName": "chr6", "start": 105000000, "end": 114200000, "cytoband": "q21", "stain": "gneg"},
      {"referenceName": "chr6", "start": 114200000, "end": 117900000, "cytoband": "q22.1", "stain": "gpos75"},
      {"referenceName": "chr6", "start": 117900000, "end": 118100000, "cytoband": "q22.2", "stain": "gneg"},
      {"referenceName": "chr6", "start": 118100000, "end": 125800000, "cytoband": "q22.31", "stain": "gpos100"},
      {"referenceName": "chr6", "start": 125800000, "end": 126800000, "cytoband": "q22.32", "stain": "gneg"},
      {"referenceName": "chr6", "start": 126800000, "end": 130000000, "cytoband": "q22.33", "stain": "gpos75"},
      {"referenceName": "chr6", "start": 130000000, "end": 130900000, "cytoband": "q23.1", "stain": "gneg"},
      {"referenceName": "chr6", "start": 130900000, "end": 134700000, "cytoband": "q23.2", "stain": "gpos50"},
      {"referenceName": "chr6", "start": 134700000, "end": 138300000, "cytoband": "q23.3", "stain": "gneg"},
      {"referenceName": "chr6", "start": 138300000, "end": 142200000, "cytoband": "q24.1", "stain": "gpos75"},
      {"referenceName": "chr6", "start": 142200000, "end": 145100000, "cytoband": "q24.2", "stain": "gneg"},
      {"referenceName": "chr6", "start": 145100000, "end": 148500000, "cytoband": "q24.3", "stain": "gpos75"},
      {"referenceName": "chr6", "start": 148500000, "end": 152100000, "cytoband": "q25.1", "stain": "gneg"},
      {"referenceName": "chr6", "start": 152100000, "end": 155200000, "cytoband": "q25.2", "stain": "gpos50"},
      {"referenceName": "chr6", "start": 155200000, "end": 160600000, "cytoband": "q25.3", "stain": "gneg"},
      {"referenceName": "chr6", "start": 160600000, "end": 164100000, "cytoband": "q26", "stain": "gpos50"},
      {"referenceName": "chr6", "start": 164100000, "end": 170805979, "cytoband": "q27", "stain": "gneg"}
    ],
    "chr7": [
      {"referenceName": "chr7", "start": 0, "end": 2800000, "cytoband": "p22.3", "stain": "gneg"},
      {"referenceName": "chr7", "start": 2800000, "end": 4500000, "cytoband": "p22.2", "stain": "gpos25"},
      {"referenceName": "chr7", "start": 4500000, "end": 7200000, "cytoband": "p22.1", "stain": "gneg"},
      {"referenceName": "chr7", "start": 7200000, "end": 13700000, "cytoband": "p21.3", "stain": "gpos100"},
      {"referenceName": "chr7", "start": 13700000, "end": 16500000, "cytoband": "p21.2", "stain": "gneg"},
      {"referenceName": "chr7", "start": 16500000, "end": 20900000, "cytoband": "p21.1", "stain": "gpos100"},
      {"referenceName": "chr7", "start": 20900000, "end": 25500000, "cytoband": "p15.3", "stain": "gneg"},
      {"referenceName": "chr7", "start": 25500000, "end": 27900000, "cytoband": "p15.2", "stain": "gpos50"},
      {"referenceName": "chr7", "start": 27900000, "end": 28800000, "cytoband": "p15.1", "stain": "gneg"},
      {"referenceName": "chr7", "start": 28800000, "end": 34900000, "cytoband": "p14.3", "stain": "gpos75"},
      {"referenceName": "chr7", "start": 34900000, "end": 37100000, "cytoband": "p14.2", "stain": "gneg"},
      {"referenceName": "chr7", "start": 37100000, "end": 43300000, "cytoband": "p14.1", "stain": "gpos75"},
      {"referenceName": "chr7", "start": 43300000, "end": 45400000, "cytoband": "p13", "stain": "gneg"},
      {"referenceName": "chr7", "start": 45400000, "end": 49000000, "cytoband": "p12.3", "stain": "gpos75"},
      {"referenceName": "chr7", "start": 49000000, "end": 50500000, "cytoband": "p12.2", "stain": "gneg"},
      {"referenceName": "chr7", "start": 50500000, "end": 53900000, "cytoband": "p12.1", "stain": "gpos75"},
      {"referenceName": "chr7", "start": 53900000, "end": 58100000, "cytoband": "p11.2", "stain": "gneg"},
      {"referenceName": "chr7", "start": 58100000, "end": 60100000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr7", "start": 60100000, "end": 62100000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr7", "start": 62100000, "end": 67500000, "cytoband": "q11.21", "stain": "gneg"},
      {"referenceName": "chr7", "start": 67500000, "end": 72700000, "cytoband": "q11.22", "stain": "gpos50"},
      {"referenceName": "chr7", "start": 72700000, "end": 77900000, "cytoband": "q11.23", "stain": "gneg"},
      {"referenceName": "chr7", "start": 77900000, "end": 86700000, "cytoband": "q21.11", "stain": "gpos100"},
      {"referenceName": "chr7", "start": 86700000, "end": 88500000, "cytoband": "q21.12", "stain": "gneg"},
      {"referenceName": "chr7", "start": 88500000, "end": 91500000, "cytoband": "q21.13", "stain": "gpos75"},
      {"referenceName": "chr7", "start": 91500000, "end": 93300000, "cytoband": "q21.2", "stain": "gneg"},
      {"referenceName": "chr7", "start": 93300000, "end": 98400000, "cytoband": "q21.3", "stain": "gpos75"},
      {"referenceName": "chr7", "start": 98400000, "end": 104200000, "cytoband": "q22.1", "stain": "gneg"},
      {"referenceName": "chr7", "start": 104200000, "end": 104900000, "cytoband": "q22.2", "stain": "gpos50"},
      {"referenceName": "chr7", "start": 104900000, "end": 107800000, "cytoband": "q22.3", "stain": "gneg"},
      {"referenceName": "chr7", "start": 107800000, "end": 115000000, "cytoband": "q31.1", "stain": "gpos75"},
      {"referenceName": "chr7", "start": 115000000, "end": 117700000, "cytoband": "q31.2", "stain": "gneg"},
      {"referenceName": "chr7", "start": 117700000, "end": 121400000, "cytoband": "q31.31", "stain": "gpos75"},
      {"referenceName": "chr7", "start": 121400000, "end": 124100000, "cytoband": "q31.32", "stain": "gneg"},
      {"referenceName": "chr7", "start": 124100000, "end": 127500000, "cytoband": "q31.33", "stain": "gpos75"},
      {"referenceName": "chr7", "start": 127500000, "end": 129600000, "cytoband": "q32.1", "stain": "gneg"},
      {"referenceName": "chr7", "start": 129600000, "end": 130800000, "cytoband": "q32.2", "stain": "gpos25"},
      {"referenceName": "chr7", "start": 130800000, "end": 132900000, "cytoband": "q32.3", "stain": "gneg"},
      {"referenceName": "chr7", "start": 132900000, "end": 138500000, "cytoband": "q33", "stain": "gpos50"},
      {"referenceName": "chr7", "start": 138500000, "end": 143400000, "cytoband": "q34", "stain": "gneg"},
      {"referenceName": "chr7", "start": 143400000, "end": 148200000, "cytoband": "q35", "stain": "gpos75"},
      {"referenceName": "chr7", "start": 148200000, "end": 152800000, "cytoband": "q36.1", "stain": "gneg"},
      {"referenceName": "chr7", "start": 152800000, "end": 155200000, "cytoband": "q36.2", "stain": "gpos25"},
      {"referenceName": "chr7", "start": 155200000, "end": 159345973, "cytoband": "q36.3", "stain": "gneg"}
    ],
    "chr8": [
      {"referenceName": "chr8", "start": 0, "end": 2300000, "cytoband": "p23.3", "stain": "gneg"},
      {"referenceName": "chr8", "start": 2300000, "end": 6300000, "cytoband": "p23.2", "stain": "gpos75"},
      {"referenceName": "chr8", "start": 6300000, "end": 12800000, "cytoband": "p23.1", "stain": "gneg"},
      {"referenceName": "chr8", "start": 12800000, "end": 19200000, "cytoband": "p22", "stain": "gpos100"},
      {"referenceName": "chr8", "start": 19200000, "end": 23500000, "cytoband": "p21.3", "stain": "gneg"},
      {"referenceName": "chr8", "start": 23500000, "end": 27500000, "cytoband": "p21.2", "stain": "gpos50"},
      {"referenceName": "chr8", "start": 27500000, "end": 29000000, "cytoband": "p21.1", "stain": "gneg"},
      {"referenceName": "chr8", "start": 29000000, "end": 36700000, "cytoband": "p12", "stain": "gpos75"},
      {"referenceName": "chr8", "start": 36700000, "end": 38500000, "cytoband": "p11.23", "stain": "gneg"},
      {"referenceName": "chr8", "start": 38500000, "end": 39900000, "cytoband": "p11.22", "stain": "gpos25"},
      {"referenceName": "chr8", "start": 39900000, "end": 43200000, "cytoband": "p11.21", "stain": "gneg"},
      {"referenceName": "chr8", "start": 43200000, "end": 45200000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr8", "start": 45200000, "end": 47200000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chr8", "start": 47200000, "end": 51300000, "cytoband": "q11.21", "stain": "gneg"},
      {"referenceName": "chr8", "start": 51300000, "end": 51700000, "cytoband": "q11.22", "stain": "gpos75"},
      {"referenceName": "chr8", "start": 51700000, "end": 54600000, "cytoband": "q11.23", "stain": "gneg"},
      {"referenceName": "chr8", "start": 54600000, "end": 60600000, "cytoband": "q12.1", "stain": "gpos50"},
      {"referenceName": "chr8", "start": 60600000, "end": 61300000, "cytoband": "q12.2", "stain": "gneg"},
      {"referenceName": "chr8", "start": 61300000, "end": 65100000, "cytoband": "q12.3", "stain": "gpos50"},
      {"referenceName": "chr8", "start": 65100000, "end": 67100000, "cytoband": "q13.1", "stain": "gneg"},
      {"referenceName": "chr8", "start": 67100000, "end": 69600000, "cytoband": "q13.2", "stain": "gpos50"},
      {"referenceName": "chr8", "start": 69600000, "end": 72000000, "cytoband": "q13.3", "stain": "gneg"},
      {"referenceName": "chr8", "start": 72000000, "end": 74600000, "cytoband": "q21.11", "stain": "gpos100"},
      {"referenceName": "chr8", "start": 74600000, "end": 74700000, "cytoband": "q21.12", "stain": "gneg"},
      {"referenceName": "chr8", "start": 74700000, "end": 83500000, "cytoband": "q21.13", "stain": "gpos75"},
      {"referenceName": "chr8", "start": 83500000, "end": 85900000, "cytoband": "q21.2", "stain": "gneg"},
      {"referenceName": "chr8", "start": 85900000, "end": 92300000, "cytoband": "q21.3", "stain": "gpos100"},
      {"referenceName": "chr8", "start": 92300000, "end": 97900000, "cytoband": "q22.1", "stain": "gneg"},
      {"referenceName": "chr8", "start": 97900000, "end": 100500000, "cytoband": "q22.2", "stain": "gpos25"},
      {"referenceName": "chr8", "start": 100500000, "end": 105100000, "cytoband": "q22.3", "stain": "gneg"},
      {"referenceName": "chr8", "start": 105100000, "end": 109500000, "cytoband": "q23.1", "stain": "gpos75"},
      {"referenceName": "chr8", "start": 109500000, "end": 111100000, "cytoband": "q23.2", "stain": "gneg"},
      {"referenceName": "chr8", "start": 111100000, "end": 116700000, "cytoband": "q23.3", "stain": "gpos100"},
      {"referenceName": "chr8", "start": 116700000, "end": 118300000, "cytoband": "q24.11", "stain": "gneg"},
      {"referenceName": "chr8", "start": 118300000, "end": 121500000, "cytoband": "q24.12", "stain": "gpos50"},
      {"referenceName": "chr8", "start": 121500000, "end": 126300000, "cytoband": "q24.13", "stain": "gneg"},
      {"referenceName": "chr8", "start": 126300000, "end": 130400000, "cytoband": "q24.21", "stain": "gpos50"},
      {"referenceName": "chr8", "start": 130400000, "end": 135400000, "cytoband": "q24.22", "stain": "gneg"},
      {"referenceName": "chr8", "start": 135400000, "end": 138900000, "cytoband": "q24.23", "stain": "gpos75"},
      {"referenceName": "chr8", "start": 138900000, "end": 145138636, "cytoband": "q24.3", "stain": "gneg"}
    ],
    "chr9": [
      {"referenceName": "chr9", "start": 0, "end": 2200000, "cytoband": "p24.3", "stain": "gneg"},
      {"referenceName": "chr9", "start": 2200000, "end": 4600000, "cytoband": "p24.2", "stain": "gpos25"},
      {"referenceName": "chr9", "start": 4600000, "end": 9000000, "cytoband": "p24.1", "stain": "gneg"},
      {"referenceName": "chr9", "start": 9000000, "end": 14200000, "cytoband": "p23", "stain": "gpos75"},
      {"referenceName": "chr9", "start": 14200000, "end": 16600000, "cytoband": "p22.3", "stain": "gneg"},
      {"referenceName": "chr9", "start": 16600000, "end": 18500000, "cytoband": "p22.2", "stain": "gpos25"},
      {"referenceName": "chr9", "start": 18500000, "end": 19900000, "cytoband": "p22.1", "stain": "gneg"},
      {"referenceName": "chr9", "start": 19900000, "end": 25600000, "cytoband": "p21.3", "stain": "gpos100"},
      {"referenceName": "chr9", "start": 25600000, "end": 28000000, "cytoband": "p21.2", "stain": "gneg"},
      {"referenceName": "chr9", "start": 28000000, "end": 33200000, "cytoband": "p21.1", "stain": "gpos100"},
      {"referenceName": "chr9", "start": 33200000, "end": 36300000, "cytoband": "p13.3", "stain": "gneg"},
      {"referenceName": "chr9", "start": 36300000, "end": 37900000, "cytoband": "p13.2", "stain": "gpos25"},
      {"referenceName": "chr9", "start": 37900000, "end": 39000000, "cytoband": "p13.1", "stain": "gneg"},
      {"referenceName": "chr9", "start": 39000000, "end": 40000000, "cytoband": "p12", "stain": "gpos50"},
      {"referenceName": "chr9", "start": 40000000, "end": 42200000, "cytoband": "p11.2", "stain": "gneg"},
      {"referenceName": "chr9", "start": 42200000, "end": 43000000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chr9", "start": 43000000, "end": 45500000, "cytoband": "q11", "stain": "acen"},
      {"referenceName": "chr9", "start": 45500000, "end": 61500000, "cytoband": "q12", "stain": "gvar"},
      {"referenceName": "chr9", "start": 61500000, "end": 65000000, "cytoband": "q13", "stain": "gneg"},
      {"referenceName": "chr9", "start": 65000000, "end": 69300000, "cytoband": "q21.11", "stain": "gpos25"},
      {"referenceName": "chr9", "start": 69300000, "end": 71300000, "cytoband": "q21.12", "stain": "gneg"},
      {"referenceName": "chr9", "start": 71300000, "end": 76600000, "cytoband": "q21.13", "stain": "gpos50"},
      {"referenceName": "chr9", "start": 76600000, "end": 78500000, "cytoband": "q21.2", "stain": "gneg"},
      {"referenceName": "chr9", "start": 78500000, "end": 81500000, "cytoband": "q21.31", "stain": "gpos50"},
      {"referenceName": "chr9", "start": 81500000, "end": 84300000, "cytoband": "q21.32", "stain": "gneg"},
      {"referenceName": "chr9", "start": 84300000, "end": 87800000, "cytoband": "q21.33", "stain": "gpos50"},
      {"referenceName": "chr9", "start": 87800000, "end": 89200000, "cytoband": "q22.1", "stain": "gneg"},
      {"referenceName": "chr9", "start": 89200000, "end": 91200000, "cytoband": "q22.2", "stain": "gpos25"},
      {"referenceName": "chr9", "start": 91200000, "end": 93900000, "cytoband": "q22.31", "stain": "gneg"},
      {"referenceName": "chr9", "start": 93900000, "end": 96500000, "cytoband": "q22.32", "stain": "gpos25"},
      {"referenceName": "chr9", "start": 96500000, "end": 99800000, "cytoband": "q22.33", "stain": "gneg"},
      {"referenceName": "chr9", "start": 99800000, "end": 105400000, "cytoband": "q31.1", "stain": "gpos100"},
      {"referenceName": "chr9", "start": 105400000, "end": 108500000, "cytoband": "q31.2", "stain": "gneg"},
      {"referenceName": "chr9", "start": 108500000, "end": 112100000, "cytoband": "q31.3", "stain": "gpos25"},
      {"referenceName": "chr9", "start": 112100000, "end": 114900000, "cytoband": "q32", "stain": "gneg"},
      {"referenceName": "chr9", "start": 114900000, "end": 119800000, "cytoband": "q33.1", "stain": "gpos75"},
      {"referenceName": "chr9", "start": 119800000, "end": 123100000, "cytoband": "q33.2", "stain": "gneg"},
      {"referenceName": "chr9", "start": 123100000, "end": 127500000, "cytoband": "q33.3", "stain": "gpos25"},
      {"referenceName": "chr9", "start": 127500000, "end": 130600000, "cytoband": "q34.11", "stain": "gneg"},
      {"referenceName": "chr9", "start": 130600000, "end": 131100000, "cytoband": "q34.12", "stain": "gpos25"},
      {"referenceName": "chr9", "start": 131100000, "end": 133100000, "cytoband": "q34.13", "stain": "gneg"},
      {"referenceName": "chr9", "start": 133100000, "end": 134500000, "cytoband": "q34.2", "stain": "gpos25"},
      {"referenceName": "chr9", "start": 134500000, "end": 138394717, "cytoband": "q34.3", "stain": "gneg"}
    ],
    "chrX": [
      {"referenceName": "chrX", "start": 0, "end": 4400000, "cytoband": "p22.33", "stain": "gneg"},
      {"referenceName": "chrX", "start": 4400000, "end": 6100000, "cytoband": "p22.32", "stain": "gpos50"},
      {"referenceName": "chrX", "start": 6100000, "end": 9600000, "cytoband": "p22.31", "stain": "gneg"},
      {"referenceName": "chrX", "start": 9600000, "end": 17400000, "cytoband": "p22.2", "stain": "gpos50"},
      {"referenceName": "chrX", "start": 17400000, "end": 19200000, "cytoband": "p22.13", "stain": "gneg"},
      {"referenceName": "chrX", "start": 19200000, "end": 21900000, "cytoband": "p22.12", "stain": "gpos50"},
      {"referenceName": "chrX", "start": 21900000, "end": 24900000, "cytoband": "p22.11", "stain": "gneg"},
      {"referenceName": "chrX", "start": 24900000, "end": 29300000, "cytoband": "p21.3", "stain": "gpos100"},
      {"referenceName": "chrX", "start": 29300000, "end": 31500000, "cytoband": "p21.2", "stain": "gneg"},
      {"referenceName": "chrX", "start": 31500000, "end": 37800000, "cytoband": "p21.1", "stain": "gpos100"},
      {"referenceName": "chrX", "start": 37800000, "end": 42500000, "cytoband": "p11.4", "stain": "gneg"},
      {"referenceName": "chrX", "start": 42500000, "end": 47600000, "cytoband": "p11.3", "stain": "gpos75"},
      {"referenceName": "chrX", "start": 47600000, "end": 50100000, "cytoband": "p11.23", "stain": "gneg"},
      {"referenceName": "chrX", "start": 50100000, "end": 54800000, "cytoband": "p11.22", "stain": "gpos25"},
      {"referenceName": "chrX", "start": 54800000, "end": 58100000, "cytoband": "p11.21", "stain": "gneg"},
      {"referenceName": "chrX", "start": 58100000, "end": 61000000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chrX", "start": 61000000, "end": 63800000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chrX", "start": 63800000, "end": 65400000, "cytoband": "q11.2", "stain": "gneg"},
      {"referenceName": "chrX", "start": 65400000, "end": 68500000, "cytoband": "q12", "stain": "gpos50"},
      {"referenceName": "chrX", "start": 68500000, "end": 73000000, "cytoband": "q13.1", "stain": "gneg"},
      {"referenceName": "chrX", "start": 73000000, "end": 74700000, "cytoband": "q13.2", "stain": "gpos50"},
      {"referenceName": "chrX", "start": 74700000, "end": 76800000, "cytoband": "q13.3", "stain": "gneg"},
      {"referenceName": "chrX", "start": 76800000, "end": 85400000, "cytoband": "q21.1", "stain": "gpos100"},
      {"referenceName": "chrX", "start": 85400000, "end": 87000000, "cytoband": "q21.2", "stain": "gneg"},
      {"referenceName": "chrX", "start": 87000000, "end": 92700000, "cytoband": "q21.31", "stain": "gpos100"},
      {"referenceName": "chrX", "start": 92700000, "end": 94300000, "cytoband": "q21.32", "stain": "gneg"},
      {"referenceName": "chrX", "start": 94300000, "end": 99100000, "cytoband": "q21.33", "stain": "gpos75"},
      {"referenceName": "chrX", "start": 99100000, "end": 103300000, "cytoband": "q22.1", "stain": "gneg"},
      {"referenceName": "chrX", "start": 103300000, "end": 104500000, "cytoband": "q22.2", "stain": "gpos50"},
      {"referenceName": "chrX", "start": 104500000, "end": 109400000, "cytoband": "q22.3", "stain": "gneg"},
      {"referenceName": "chrX", "start": 109400000, "end": 117400000, "cytoband": "q23", "stain": "gpos75"},
      {"referenceName": "chrX", "start": 117400000, "end": 121800000, "cytoband": "q24", "stain": "gneg"},
      {"referenceName": "chrX", "start": 121800000, "end": 129500000, "cytoband": "q25", "stain": "gpos100"},
      {"referenceName": "chrX", "start": 129500000, "end": 131300000, "cytoband": "q26.1", "stain": "gneg"},
      {"referenceName": "chrX", "start": 131300000, "end": 134500000, "cytoband": "q26.2", "stain": "gpos25"},
      {"referenceName": "chrX", "start": 134500000, "end": 138900000, "cytoband": "q26.3", "stain": "gneg"},
      {"referenceName": "chrX", "start": 138900000, "end": 141200000, "cytoband": "q27.1", "stain": "gpos75"},
      {"referenceName": "chrX", "start": 141200000, "end": 143000000, "cytoband": "q27.2", "stain": "gneg"},
      {"referenceName": "chrX", "start": 143000000, "end": 148000000, "cytoband": "q27.3", "stain": "gpos100"},
      {"referenceName": "chrX", "start": 148000000, "end": 156040895, "cytoband": "q28", "stain": "gneg"}
    ],
    "chrY": [
      {"referenceName": "chrY", "start": 0, "end": 300000, "cytoband": "p11.32", "stain": "gneg"},
      {"referenceName": "chrY", "start": 300000, "end": 600000, "cytoband": "p11.31", "stain": "gpos50"},
      {"referenceName": "chrY", "start": 600000, "end": 10300000, "cytoband": "p11.2", "stain": "gneg"},
      {"referenceName": "chrY", "start": 10300000, "end": 10400000, "cytoband": "p11.1", "stain": "acen"},
      {"referenceName": "chrY", "start": 10400000, "end": 10600000, "cytoband": "q11.1", "stain": "acen"},
      {"referenceName": "chrY", "start": 10600000, "end": 12400000, "cytoband": "q11.21", "stain": "gneg"},
      {"referenceName": "chrY", "start": 12400000, "end": 17100000, "cytoband": "q11.221", "stain": "gpos50"},
      {"referenceName": "chrY", "start": 17100000, "end": 19600000, "cytoband": "q11.222", "stain": "gneg"},
      {"referenceName": "chrY", "start": 19600000, "end": 23800000, "cytoband": "q11.223", "stain": "gpos50"},
      {"referenceName": "chrY", "start": 23800000, "end": 26600000, "cytoband": "q11.23", "stain": "gneg"},
      {"referenceName": "chrY", "start": 26600000, "end": 57227415, "cytoband": "q12", "stain": "gvar"}
  ]
  }
}
